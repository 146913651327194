import React from 'react';
import {
  TwtPackage3dInclude,
  TwtPackageFloorplanInclude,
  TwtPackagePhotoAddOns,
  TwtPackagePhotoInclude,
  TwtPackageTimeOfDay,
} from '~/tenants/twt/package/common';
import { TenantPackageIncludes } from '~/tenants/common/package';
import { TenantPerformableAddOns } from '~/tenants/common/performable';
import TwtMatterportConfig from '~/tenants/twt/performable/matterport/TwtMatterportConfig';
import { PersonaType } from '~/tenants/common/TenantPersona';
import { TwtMatterportFormInputs } from '~/tenants/twt/performable/matterport/TwtMatterportForm';
import Heading from '~/components/layout/Heading';
import Sections from '~/components/layout/Sections';
import PerformableGroupForm from '~/components/performable/PerformableGroupForm';
import TwtFloorplanConfig from '~/tenants/twt/performable/floorplan/TwtFloorplanConfig';
import { TwtOrderType } from '~/tenants/twt/model/enums';

export default function TwtMatterportTourForm() {
  return (
    <>
      <TenantPackageIncludes>
        <TwtPackagePhotoInclude />
        <PerformableGroupForm performable={TwtFloorplanConfig}>
          <TwtPackageFloorplanInclude />
        </PerformableGroupForm>
        <TwtPackage3dInclude />
      </TenantPackageIncludes>

      <div className="border-theme-separator border-t pt-6 mt-6">
        <Sections>
          <Heading title="Matterport Information" />
          <PerformableGroupForm performable={TwtMatterportConfig}>
            <TwtMatterportFormInputs persona={PersonaType.CUSTOMER} create />
          </PerformableGroupForm>
        </Sections>
      </div>

      <TwtPackageTimeOfDay />

      <TenantPerformableAddOns persona={PersonaType.CUSTOMER}>
        <TwtPackagePhotoAddOns type={TwtOrderType.FOR_SALE_LISTING} />
      </TenantPerformableAddOns>
    </>
  );
}
