import Big from 'big.js';
import { DateTime } from 'luxon';
import { match } from 'ts-pattern';
import { TenantJobLine, addressToArea, addressToStreet } from '~/lib/model';
import { FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata } from '~/lib/zod';
import BreJob from '~/tenants/bre/model/BreJob';
import BreWebsiteConfig, { WebsiteType } from '~/tenants/bre/performable/website/BreWebsiteConfig';
import { BreWebsiteRate } from '~/tenants/bre/performable/website/BreWebsiteData';
import {
  TenantSubscriptionMetadata,
  TenantSubscriptionStatus,
  TenantSubscriptionType,
} from '~/tenants/common/TenantPersona';

export default class BreWebsiteJob extends BreJob<typeof BreWebsiteConfig> {
  get performable() {
    return BreWebsiteConfig;
  }

  get configurable(): boolean {
    return true;
  }

  get eligible(): boolean {
    return !this.isMarketing;
  }

  get typeText(): string {
    const { type } = this.metadata;
    return BreWebsiteJob.typeText(type);
  }

  static typeText(type: WebsiteType): string {
    return match(type)
      .with('website', () => 'Single Property Website')
      .with('renewal', () => 'Subscription Renewal')
      .exhaustive();
  }

  defaultValue(): ZodVersionedMetadata<(typeof BreWebsiteConfig)['schema']> {
    return {
      version: FIRST_VERSION_TIMESTAMP,
      type: 'website',
    };
  }

  subscription(): TenantSubscriptionMetadata {
    const { type } = this.metadata;
    const { RENEWAL, WEBSITE } = BreWebsiteRate[this.order.metadata.type];
    const rate = match(type)
      .with('website', () => Number(WEBSITE))
      .with('renewal', () => Number(RENEWAL))
      .exhaustive();

    return {
      type: TenantSubscriptionType.MICROSITE,
      name: this.order.address
        ? `${addressToStreet(this.order.address)}, ${addressToArea(this.order.address)}`
        : 'Single Property Website',
      status: TenantSubscriptionStatus.ACTIVE,
      created: DateTime.now().toJSDate().toISOString(),
      order_id: this.order.id,
      job_id: this.id,
      months: 12,
      cost: new Big(rate).toNumber(),
      expires: DateTime.now().plus({ month: 12 }).toJSDate().toISOString(),
      renew: false,
      payments: {},
      domain: 'https://example.com',
    };
  }

  revenueLines(): TenantJobLine[] {
    const { type, domain } = this.metadata;
    const { DOMAIN, RENEWAL, WEBSITE } = BreWebsiteRate[this.order.metadata.type];
    const lines: TenantJobLine[] = [];

    if (type === 'website') {
      lines.push({
        id: 'website_type',
        description: this.typeText,
        amount: new Big(WEBSITE),
        discountable: true,
      });
    }

    if (type === 'renewal') {
      lines.push({
        id: 'renewal',
        description: this.typeText,
        amount: new Big(RENEWAL),
      });
    }

    if (domain) {
      lines.push({
        id: 'domain',
        description: `Custom Domain (www.${domain})`,
        amount: new Big(DOMAIN),
      });
    }

    return lines;
  }
}
