import FormGroup from '~/components/form/FormGroup';
import { BreOrderContext } from '~/tenants/bre/model/BreOrderContext';
import BreListingDisclosure from '~/tenants/bre/performable/BreListingDisclosure';
import { BreFloorplanRate } from '~/tenants/bre/performable/floorplan/BreFloorplanData';
import { TenantPerformableFormProps } from '~/tenants/common/form';
import { TenantPackageAddOnCheckbox } from '~/tenants/common/package';
import { TenantPerformableAddOns } from '~/tenants/common/performable';

export default function BreFloorplanForm({ context, persona }: TenantPerformableFormProps<BreOrderContext>) {
  const { RUSH } = BreFloorplanRate[context.metadata.type];

  return (
    <FormGroup>
      <TenantPerformableAddOns persona={persona}>
        <TenantPackageAddOnCheckbox
          name="rush"
          title="Rush"
          description="Need the floor plan before noon on the next business day?"
          image="/tenant/bre/rush.jpg"
          persona={persona}
          cost={RUSH}
        />
      </TenantPerformableAddOns>

      <BreListingDisclosure />
    </FormGroup>
  );
}
