import ZodFieldAddress from '~/components/zod/ZodFieldAddress';
import ZodFieldInput from '~/components/zod/ZodFieldInput';
import { BreOrderType } from '~/tenants/bre/model/BreOrderContext';
import { BrePhotosRate } from '~/tenants/bre/performable/photos/BrePhotosData';
import { PersonaType } from '~/tenants/common/TenantPersona';
import { TenantPackageAddOnRepeat } from '~/tenants/common/package';

interface BrePhotosLocalAreaAddOnProps {
  persona: PersonaType;
  type: BreOrderType;
}

export default function BrePhotosLocalAreaAddOn({ persona, type }: BrePhotosLocalAreaAddOnProps) {
  const { LOCAL_AREA } = BrePhotosRate[type];

  return (
    <TenantPackageAddOnRepeat
      name="local_area"
      title="Local Area Photos"
      description="Add-On local area photos if you can't locate an image from our gallery"
      image="/tenant/bre/photos/local_area.jpg"
      persona={persona}
      cost={LOCAL_AREA}
    >
      <div className="flex gap-2">
        <div className="w-1/3">
          <ZodFieldInput name="name" placeholder="Name" />
        </div>
        <div className="w-2/3">
          <ZodFieldAddress name="address" editable />
        </div>
      </div>
    </TenantPackageAddOnRepeat>
  );
}
