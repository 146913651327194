import { createPerformableConfig, TenantId } from '~/tenants/common/registry';
import { coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP } from '~/lib/zod';
import z from 'zod';

export default createPerformableConfig(
  'iguide',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
    }),
  },
  {
    name: '3D IGuide',
    // TODO: Joel: Revisit this
    thumbnail: 'tenant/twt/public/matterport.jpg',
    short:
      '3D Imaging services help to provide high-qualirty, in-depth visual representations, providing a sort of virtual walkthrough for potential buyers.',
    tenant: TenantId.MPI,
    images: [],
    group: '3D',
  },
);
