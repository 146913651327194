import { NeedBy } from '~/tenants/bre/performable/common';
import { FlyerCount, FlyerPaper, FlyerSide } from '~/tenants/bre/performable/print/flyers/BreFlyersConfig';

type CountMap = Record<FlyerCount, string>;

type PaperMap = Record<FlyerPaper, CountMap>;

type SideMap = Record<FlyerSide, Partial<PaperMap>>;

type NeedByMap = Record<NeedBy, Partial<SideMap>>;

export const BreFlyersRate: NeedByMap = {
  next_day: {
    1: {
      low_gloss: {
        50: '130',
        100: '155',
        150: '185',
        200: '210',
      },
      heavy_gloss: {
        50: '140',
        100: '170',
        150: '195',
        200: '225',
      },
      heavy_gloss_uv: {
        50: '155',
        100: '180',
        150: '200',
        200: '225',
      },
      super_heavy_gloss_uv: {
        50: '165',
        100: '185',
        150: '215',
        200: '235',
      },
      linen_cover: {
        50: '175',
        100: '230',
        150: '300',
        200: '370',
      },
    },
    2: {
      low_gloss: {
        50: '155',
        100: '190',
        150: '245',
        200: '285',
      },
      heavy_gloss: {
        50: '165',
        100: '210',
        150: '250',
        200: '300',
      },
      heavy_gloss_uv: {
        50: '185',
        100: '230',
        150: '290',
        200: '340',
      },
      super_heavy_gloss_uv: {
        50: '195',
        100: '245',
        150: '305',
        200: '365',
      },
      linen_cover: {
        50: '220',
        100: '290',
        150: '385',
        200: '485',
      },
    },
    4: {
      heavy_gloss_uv: {
        50: '270',
        100: '360',
        150: '445',
        200: '560',
      },
      super_heavy_gloss_uv: {
        50: '340',
        100: '460',
        150: '590',
        200: '740',
      },
    },
  },
  same_day: {
    1: {
      low_gloss: {
        50: '175',
        100: '200',
        150: '220',
        200: '245',
      },
      heavy_gloss: {
        50: '200',
        100: '225',
        150: '245',
        200: '275',
      },
      heavy_gloss_uv: {
        50: '185',
        100: '210',
        150: '230',
        200: '260',
      },
      super_heavy_gloss_uv: {
        50: '190',
        100: '215',
        150: '235',
        200: '265',
      },
      linen_cover: {
        50: '195',
        100: '255',
        150: '325',
        200: '400',
      },
    },
    2: {
      low_gloss: {
        50: '195',
        100: '230',
        150: '280',
        200: '320',
      },
      heavy_gloss: {
        50: '215',
        100: '255',
        150: '315',
        200: '345',
      },
      heavy_gloss_uv: {
        50: '225',
        100: '270',
        150: '330',
        200: '375',
      },
      super_heavy_gloss_uv: {
        50: '235',
        100: '280',
        150: '340',
        200: '395',
      },
      linen_cover: {
        50: '245',
        100: '310',
        150: '415',
        200: '520',
      },
    },
    4: {
      heavy_gloss_uv: {
        50: '335',
        100: '415',
        150: '510',
        200: '610',
      },
      super_heavy_gloss_uv: {
        50: '365',
        100: '490',
        150: '635',
        200: '805',
      },
    },
  },
};

export const BreFlyersPay: NeedByMap = {
  next_day: {
    1: {
      low_gloss: {
        50: '115',
        100: '135',
        150: '165',
        200: '185',
      },
      heavy_gloss: {
        50: '125',
        100: '150',
        150: '170',
        200: '195',
      },
      heavy_gloss_uv: {
        50: '135',
        100: '155',
        150: '175',
        200: '195',
      },
      super_heavy_gloss_uv: {
        50: '140',
        100: '160',
        150: '185',
        200: '205',
      },
      linen_cover: {
        50: '150',
        100: '200',
        150: '260',
        200: '320',
      },
    },
    2: {
      low_gloss: {
        50: '135',
        100: '165',
        150: '215',
        200: '250',
      },
      heavy_gloss: {
        50: '145',
        100: '180',
        150: '220',
        200: '260',
      },
      heavy_gloss_uv: {
        50: '160',
        100: '200',
        150: '250',
        200: '295',
      },
      super_heavy_gloss_uv: {
        50: '170',
        100: '210',
        150: '265',
        200: '315',
      },
      linen_cover: {
        50: '190',
        100: '250',
        150: '335',
        200: '420',
      },
    },
    4: {
      heavy_gloss_uv: {
        50: '235',
        100: '310',
        150: '385',
        200: '485',
      },
      super_heavy_gloss_uv: {
        50: '295',
        100: '400',
        150: '510',
        200: '640',
      },
    },
  },
  same_day: {
    1: {
      low_gloss: {
        50: '150',
        100: '170',
        150: '190',
        200: '210',
      },
      heavy_gloss: {
        50: '175',
        100: '195',
        150: '215',
        200: '240',
      },
      heavy_gloss_uv: {
        50: '160',
        100: '180',
        150: '200',
        200: '220',
      },
      super_heavy_gloss_uv: {
        50: '165',
        100: '185',
        150: '205',
        200: '230',
      },
      linen_cover: {
        50: '170',
        100: '220',
        150: '280',
        200: '345',
      },
    },
    2: {
      low_gloss: {
        50: '170',
        100: '200',
        150: '240',
        200: '275',
      },
      heavy_gloss: {
        50: '190',
        100: '225',
        150: '270',
        200: '300',
      },
      heavy_gloss_uv: {
        50: '195',
        100: '235',
        150: '285',
        200: '325',
      },
      super_heavy_gloss_uv: {
        50: '205',
        100: '245',
        150: '295',
        200: '340',
      },
      linen_cover: {
        50: '210',
        100: '270',
        150: '360',
        200: '450',
      },
    },
    4: {
      heavy_gloss_uv: {
        50: '290',
        100: '360',
        150: '445',
        200: '530',
      },
      super_heavy_gloss_uv: {
        50: '315',
        100: '425',
        150: '550',
        200: '700',
      },
    },
  },
};
