import FormGroup from '~/components/form/FormGroup';
import { FormHorizontal } from '~/components/form/layout';
import { useTz } from '~/components/hooks/useTz';
import { InputCardOption } from '~/components/input/InputCards';
import ZodFieldDate from '~/components/zod/ZodFieldDate';
import ZodFieldCards from '~/components/zod/ZodFieldCards';
import { isBusinessDay, plusBusinessDays } from '~/lib/datettime';
import { BreOrderContext } from '~/tenants/bre/model/BreOrderContext';
import BreDelivery from '~/tenants/bre/performable/print/BreDelivery';
import { countSchema } from '~/tenants/bre/performable/print/folders/BreFoldersConfig';
import { BreFoldersRate } from '~/tenants/bre/performable/print/folders/BreFoldersData';
import { TenantPerformableFormProps } from '~/tenants/common/form';

const COUNT_OPTS = countSchema.options.map<InputCardOption>((value) => {
  const rate = BreFoldersRate[value];

  return {
    title: `${value} folders`,
    cost: rate,
    disabled: !rate,
    value,
  };
});

export default function BreFoldersForm({ context }: TenantPerformableFormProps<BreOrderContext>) {
  const tz = useTz();

  return (
    <FormGroup>
      <FormHorizontal name="count" label="Count" required>
        <ZodFieldCards name="count" options={COUNT_OPTS} cols={2} required />
      </FormHorizontal>
      <FormHorizontal name="need_by" label="Need By" required>
        <ZodFieldDate
          name="need_by"
          filter={(date) => isBusinessDay(date) && date >= plusBusinessDays(tz().startOf('day'), 7)}
        />
      </FormHorizontal>
      <BreDelivery name="delivery" label="Delivery" context={context} required free />
    </FormGroup>
  );
}
