import z from 'zod';
import dedent from 'dedent';
import { FIRST_VERSION_TIMESTAMP, coerceBoolean, coerceLiteralNumberRequired, zodTDateISODate } from '~/lib/zod';
import { TenantId, createPerformableConfig } from '~/tenants/common/registry';
import { deliverySchema } from '~/tenants/bre/performable/common';
import { layoutSchema } from '~/tenants/bre/performable/print/design/BreDesignConfig';

export type BookletSide = z.infer<typeof sideSchema>;

export type BookletCount = z.infer<typeof countSchema>;

export type BookletPaper = z.infer<typeof paperSchema>;

export const sideSchema = z.enum([
  '8',
  '12',
  '16',
  '20',
  '24',
  '28',
]);

export const countSchema = z.enum([
  '50',
  '100',
  '150',
  '200',
]);

export const paperSchema = z.enum([
  'heavy_low_gloss',
  'heavy_low_gloss_uv',
  'heavy_heavy_gloss_uv',
  'super_heavy_low_gloss_uv',
]);

export default createPerformableConfig(
  'booklets',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
      sides: sideSchema,
      count: countSchema,
      paper: paperSchema,
      need_by: zodTDateISODate(),
      delivery: deliverySchema,
      no_free_uv: coerceBoolean().optional(),
      content_writing: coerceBoolean().optional(),
      design: layoutSchema.optional(),
    }),
  },
  {
    name: 'Luxury Booklets',
    group: 'Property Marketing',
    images: [],
    tenant: TenantId.BEYOND_RE_MARKETING,
    short: dedent`
      Print 8.5" square luxury booklets from 8 to 24 pages on premium paper. \
      Need content? Add expert writing for a small fee!
    `,
  },
);
