import FormGroup from '~/components/form/FormGroup';
import { FormHorizontal } from '~/components/form/layout';
import ZodFieldInput from '~/components/zod/ZodFieldInput';
import { BreOrderContext, BreOrderType } from '~/tenants/bre/model/BreOrderContext';
import BreListingDisclosure from '~/tenants/bre/performable/BreListingDisclosure';
import BreCinematicAddOns from '~/tenants/bre/performable/cinematic/BreCinematicAddOns';
import { BreCinematicRate } from '~/tenants/bre/performable/cinematic/BreCinematicData';
import { TenantPerformableFormProps } from '~/tenants/common/form';
import { TenantPackageAddOnCheckbox } from '~/tenants/common/package';
import { TenantPerformableAddOns } from '~/tenants/common/performable';

export default function BreCinematicForm({ context, persona }: TenantPerformableFormProps<BreOrderContext>) {
  const { RUSH } = BreCinematicRate[context.metadata.type];
  return (
    <FormGroup>
      {context.metadata.type === BreOrderType.COMMERCIAL && (
        <FormHorizontal name="duration" label="Duration (mins)">
          <ZodFieldInput name="duration" type="number" />
        </FormHorizontal>
      )}

      <TenantPerformableAddOns persona={persona}>
        <TenantPackageAddOnCheckbox
          name="rush"
          title="Rush"
          description="Need your video by the next business day instead of the second business day?"
          image="/tenant/bre/rush.jpg"
          persona={persona}
          cost={RUSH}
        />
        <BreCinematicAddOns persona={persona} type={context.metadata.type} />
      </TenantPerformableAddOns>

      <BreListingDisclosure />
    </FormGroup>
  );
}
