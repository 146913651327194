import { match } from 'ts-pattern';
import Message from '~/components/Message';
import FormGroup from '~/components/form/FormGroup';
import { FormHorizontal } from '~/components/form/layout';
import { useTz } from '~/components/hooks/useTz';
import { InputCardOption } from '~/components/input/InputCards';
import ZodFieldCheckbox from '~/components/zod/ZodFieldCheckbox';
import ZodFieldDate from '~/components/zod/ZodFieldDate';
import ZodFieldCards from '~/components/zod/ZodFieldCards';
import { useZodFormFieldSingleValue } from '~/components/zod/ZodForm';
import { isBusinessDay, plusBusinessDays } from '~/lib/datettime';
import { BreOrderContext } from '~/tenants/bre/model/BreOrderContext';
import BreDelivery from '~/tenants/bre/performable/print/BreDelivery';
import { RiderShipping, shippingSchema, holesSchema } from '~/tenants/bre/performable/print/riders/BreRidersConfig';
import BreRidersJob from '~/tenants/bre/performable/print/riders/BreRidersJob';
import { TenantPerformableFormProps } from '~/tenants/common/form';

const HOLES_OPTS = holesSchema.options.map<InputCardOption>((value) => ({
  title: match(value)
    .with('2', () => '2 holes')
    .with('4', () => '4 holes (top and bottom)')
    .exhaustive(),
  description: 'rider',
  value,
}));

const SHIPPING_OPTS = shippingSchema.options.map<InputCardOption>((value) => ({
  title: BreRidersJob.shippingType(value),
  description: 'delivered',
  cost: BreRidersJob.shippingFee(value),
  value,
}));

export default function BreRidersForm({ context }: TenantPerformableFormProps<BreOrderContext>) {
  const tz = useTz();
  const shipping = useZodFormFieldSingleValue<RiderShipping>('shipping');

  const hasSameDayFlyers = BreRidersJob.hasSameDayFlyers(context);

  return (
    <FormGroup>
      <FormHorizontal name="holes" label="Holes" required>
        <ZodFieldCards name="holes" options={HOLES_OPTS} cols={2} required />
      </FormHorizontal>
      {!hasSameDayFlyers && (
        <FormHorizontal name="shipping" label="Shipping" required>
          <ZodFieldCards name="shipping" options={SHIPPING_OPTS} cols={2} required />
        </FormHorizontal>
      )}
      <FormHorizontal name={'need_by'} label="Need By" required>
        <ZodFieldDate
          name="need_by"
          filter={(date) => {
            const days = shipping === 'courier' ? 3 : 4;
            return isBusinessDay(date) && date >= plusBusinessDays(tz().startOf('day'), days);
          }}
        />
      </FormHorizontal>
      {!hasSameDayFlyers && shipping === 'courier' && (
        <BreDelivery name="delivery" label="Delivery" context={context} required />
      )}
      <FormHorizontal name="no_qr_code" label="QR Code">
        <label className="flex gap-2 cursor-pointer" htmlFor="no_qr_code">
          <ZodFieldCheckbox id="no_qr_code" name="no_qr_code" />
          Do not include a QR Code.
        </label>
      </FormHorizontal>

      {hasSameDayFlyers && (
        <Message type="info">
          Enjoy <b>free delivery</b> with your same-day printed flyers!
        </Message>
      )}
    </FormGroup>
  );
}
