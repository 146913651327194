import dedent from 'dedent';
import { registerTenant, TenantId } from '~/tenants/common/registry';
import { BreOrderSchema } from '~/tenants/bre/model/BreOrderContext';
import { BreOrder } from '~/tenants/bre/model/BreOrder';
import { TimeZone } from '~/lib/enum';
import { datetime } from '~/lib/datettime';
import BreProvider from '~/tenants/bre/model/BreProvider';
import BreOrderForm from '~/tenants/bre/forms/BreOrderForm';
import EmptyForm from '~/tenants/common/forms/EmptyForm';
import BreCustomer, { BreCustomerSchema } from '~/tenants/bre/model/BreCustomer';
import { BRE_PROVIDER_DATA, BRE_TRAVEL_FEES } from '~/tenants/bre/data';
import BreAerialConfig from '~/tenants/bre/performable/aerial/BreAerialConfig';
import BreAerialJob from '~/tenants/bre/performable/aerial/BreAerialJob';
import BreAerialForm from '~/tenants/bre/performable/aerial/BreAerialForm';
import BreAerialSubmitForm from '~/tenants/bre/performable/aerial/BreAerialSubmitForm';
import BreBoostConfig from '~/tenants/bre/performable/boost/BreBoostConfig';
import BreBoostJob from '~/tenants/bre/performable/boost/BreBoostJob';
import BreBoostForm from '~/tenants/bre/performable/boost/BreBoostForm';
import BreCinematicConfig from '~/tenants/bre/performable/cinematic/BreCinematicConfig';
import BreCinematicJob from '~/tenants/bre/performable/cinematic/BreCinematicJob';
import BreCinematicForm from '~/tenants/bre/performable/cinematic/BreCinematicForm';
import BreCinematicSubmitForm from '~/tenants/bre/performable/cinematic/BreCinematicSubmitForm';
import BreDuskConfig from '~/tenants/bre/performable/dusk/BreDuskConfig';
import BreDuskJob from '~/tenants/bre/performable/dusk/BreDuskJob';
import BreDuskForm from '~/tenants/bre/performable/dusk/BreDuskForm';
import BreFloorplanConfig from '~/tenants/bre/performable/floorplan/BreFloorplanConfig';
import BreFloorplanJob from '~/tenants/bre/performable/floorplan/BreFloorplanJob';
import BreFloorplanForm from '~/tenants/bre/performable/floorplan/BreFloorplanForm';
import BreMatterportConfig from '~/tenants/bre/performable/matterport/BreMatterportConfig';
import BreMatterportJob from '~/tenants/bre/performable/matterport/BreMatterportJob';
import BreMatterportForm from '~/tenants/bre/performable/matterport/BreMatterportForm';
import BrePhotosConfig from '~/tenants/bre/performable/photos/BrePhotosConfig';
import BrePhotosJob from '~/tenants/bre/performable/photos/BrePhotosJob';
import BrePhotosForm from '~/tenants/bre/performable/photos/BrePhotosForm';
import BrePortraitsConfig from '~/tenants/bre/performable/portraits/BrePortraitsConfig';
import BrePortraitsJob from '~/tenants/bre/performable/portraits/BrePortraitsJob';
import BrePortraitsForm from '~/tenants/bre/performable/portraits/BrePortraitsForm';
import BreSocialConfig from '~/tenants/bre/performable/social/BreSocialConfig';
import BreSocialJob from '~/tenants/bre/performable/social/BreSocialJob';
import BreSocialForm from '~/tenants/bre/performable/social/BreSocialForm';
import BreSocialSubmitForm from '~/tenants/bre/performable/social/BreSocialSubmitForm';
import BreStagingConfig from '~/tenants/bre/performable/staging/BreStagingConfig';
import BreStagingJob from '~/tenants/bre/performable/staging/BreStagingJob';
import BreStagingForm from '~/tenants/bre/performable/staging/BreStagingForm';
import BreTwilightConfig from '~/tenants/bre/performable/twilight/BreTwilightConfig';
import BreTwilightJob from '~/tenants/bre/performable/twilight/BreTwilightJob';
import BreTwilightForm from '~/tenants/bre/performable/twilight/BreTwilightForm';
import BreWebsiteConfig from '~/tenants/bre/performable/website/BreWebsiteConfig';
import BreWebsiteJob from '~/tenants/bre/performable/website/BreWebsiteJob';
import BreWebsiteForm from '~/tenants/bre/performable/website/BreWebsiteForm';
import BreSocialPackageConfig from '~/tenants/bre/package/social/BreSocialPackageConfig';
import BreSocialPackageForm from '~/tenants/bre/package/social/BreSocialPackageForm';
import BrePhotoPackageConfig from '~/tenants/bre/package/photo/BrePhotoPackageConfig';
import BrePhotoPackageForm from '~/tenants/bre/package/photo/BrePhotoPackageForm';
import BreOrderWizardType from '~/tenants/bre/forms/BreOrderWizardType';
import BreCinematicsPackageConfig from '~/tenants/bre/package/cinematics/BreCinematicsPackageConfig';
import BreCinematicPackageForm from '~/tenants/bre/package/cinematics/BreCinematicsPackageForm';
import BrePhotosSubmitForm from '~/tenants/bre/performable/photos/BrePhotosSubmitForm';
import BreSlideshowConfig from '~/tenants/bre/performable/slideshow/BreSlideshowConfig';
import BreSlideshowJob from '~/tenants/bre/performable/slideshow/BreSlideshowJob';
import BreSlideshowForm from '~/tenants/bre/performable/slideshow/BreSlideshowForm';
import BreMatterportSubmitForm from '~/tenants/bre/performable/matterport/BreMatterportSubmitForm';
import BreFlyersConfig from '~/tenants/bre/performable/print/flyers/BreFlyersConfig';
import BreFlyersJob from '~/tenants/bre/performable/print/flyers/BreFlyersJob';
import BreFlyersForm from '~/tenants/bre/performable/print/flyers/BreFlyersForm';
import BreBrochuresConfig from '~/tenants/bre/performable/print/brochures/BreBrochuresConfig';
import BreBrochuresJob from '~/tenants/bre/performable/print/brochures/BreBrochuresJob';
import BreBrochuresForm from '~/tenants/bre/performable/print/brochures/BreBrochuresForm';
import BreBookletsConfig from '~/tenants/bre/performable/print/booklets/BreBookletsConfig';
import BreBookletsJob from '~/tenants/bre/performable/print/booklets/BreBookletsJob';
import BreBookletsForm from '~/tenants/bre/performable/print/booklets/BreBookletsForm';
import BrePostcardsPrintOnlyConfig from '~/tenants/bre/performable/print/postcards/printonly/BrePostcardsPrintOnlyConfig';
import BrePostcardsPrintOnlyJob from '~/tenants/bre/performable/print/postcards/printonly/BrePostcardsPrintOnlyJob';
import BrePostcardsPrintOnlyForm from '~/tenants/bre/performable/print/postcards/printonly/BrePostcardsPrintOnlyForm';
import BrePostcardsMailingConfig from '~/tenants/bre/performable/print/postcards/mailing/BrePostcardsMailingConfig';
import BrePostcardsMailingJob from '~/tenants/bre/performable/print/postcards/mailing/BrePostcardsMailingJob';
import BrePostcardsMailingForm from '~/tenants/bre/performable/print/postcards/mailing/BrePostcardsMailingForm';
import BreLetterheadsConfig from '~/tenants/bre/performable/print/letterheads/BreLetterheadsConfig';
import BreLetterheadsJob from '~/tenants/bre/performable/print/letterheads/BreLetterheadsJob';
import BreLetterheadsForm from '~/tenants/bre/performable/print/letterheads/BreLetterheadsForm';
import BreEnvelopesConfig from '~/tenants/bre/performable/print/envelopes/BreEnvelopesConfig';
import BreEnvelopesJob from '~/tenants/bre/performable/print/envelopes/BreEnvelopesJob';
import BreEnvelopesForm from '~/tenants/bre/performable/print/envelopes/BreEnvelopesForm';
import BreBusinessCardsConfig from '~/tenants/bre/performable/print/cards/BreBusinessCardsConfig';
import BreBusinessCardsJob from '~/tenants/bre/performable/print/cards/BreBusinessCardsJob';
import BreBusinessCardsForm from '~/tenants/bre/performable/print/cards/BreBusinessCardsForm';
import BreDesignConfig from '~/tenants/bre/performable/print/design/BreDesignConfig';
import BreDesignJob from '~/tenants/bre/performable/print/design/BreDesignJob';
import BreDesignForm from '~/tenants/bre/performable/print/design/BreDesignForm';
import BreSignsConfig from '~/tenants/bre/performable/print/signs/BreSignsConfig';
import BreSignsJob from '~/tenants/bre/performable/print/signs/BreSignsJob';
import BreSignsForm from '~/tenants/bre/performable/print/signs/BreSignsForm';
import BreFoldersConfig from '~/tenants/bre/performable/print/folders/BreFoldersConfig';
import BreFoldersJob from '~/tenants/bre/performable/print/folders/BreFoldersJob';
import BreFoldersForm from '~/tenants/bre/performable/print/folders/BreFoldersForm';
import BreCoversConfig from '~/tenants/bre/performable/print/covers/BreCoversConfig';
import BreCoversJob from '~/tenants/bre/performable/print/covers/BreCoversJob';
import BreCoversForm from '~/tenants/bre/performable/print/covers/BreCoversForm';
import BreRidersConfig from '~/tenants/bre/performable/print/riders/BreRidersConfig';
import BreRidersJob from '~/tenants/bre/performable/print/riders/BreRidersJob';
import BreRidersForm from '~/tenants/bre/performable/print/riders/BreRidersForm';
import BreGuidesConfig from '~/tenants/bre/performable/print/guides/BreGuidesConfig';
import BreGuidesJob from '~/tenants/bre/performable/print/guides/BreGuidesJob';
import BreGuidesForm from '~/tenants/bre/performable/print/guides/BreGuidesForm';
import BreCustomConfig from '~/tenants/bre/performable/custom/BreCustomConfig';
import BreCustomJob from '~/tenants/bre/performable/custom/BreCustomJob';
import BreCustomForm from '~/tenants/bre/performable/custom/BreCustomForm';
import BreCustomerFrom from '~/tenants/bre/forms/BreCustomerForm';
import BreOrderTimes from '~/tenants/bre/forms/BreOrderTimes';
import BreFpvVideoConfig from '~/tenants/bre/performable/fvp/BreFpvVideoConfig';
import BreFpvVideoJob from '~/tenants/bre/performable/fvp/BreFpvVideoJob';
import BreFpvVideoForm from '~/tenants/bre/performable/fvp/BreFpvVideoForm';

export default function registerBre() {
  registerTenant(TenantId.BEYOND_RE_MARKETING, BreOrderSchema, {
    name: 'Beyond RE Marketing',
    domain: 'bre.photology.co',
    logoRaster: 'https://optimize.photology.co/tenant/bre/logo.png',
    legal: 'TBD',
    phone: '+15104409153',
    email: 'bre@photology.co',
    emailEnable: false,
    background: '#402040',
    rainforestMerchantId: 'mid_2swl82pdDqHR4eJrxpYiLrAwDat',
    rainforestMerchantApplicationId: 'app_2swl814MWn3iQ57dPOlj2W5I5I2',
    scheduleStartTime: '08:00',
    scheduleEndTime: '20:00',
    resend: 're_5aDzRJPq_X2LfmZKyb6nMEG989ourG2vE',
    customerClass: BreCustomer,
    customerSchema: BreCustomerSchema,
    locations: [
      {
        slug: 'sjc',
        name: 'San Jose',
        timezone: TimeZone.US_CENTRAL,
        address: {
          line1: '15495 Los Gatos Boulevard',
          line2: null,
          city: 'Los Gatos',
          state: 'CA',
          zip: '95032',
          long: -121.9607408,
          lat: 37.2442195,
        },
      },
    ],
    orderClass: BreOrder,
    packages: [
      {
        config: BrePhotoPackageConfig,
        form: BrePhotoPackageForm,
      },
      {
        config: BreSocialPackageConfig,
        form: BreSocialPackageForm,
      },
      {
        config: BreCinematicsPackageConfig,
        form: BreCinematicPackageForm,
      },
    ],
    providerClass: BreProvider,
    providers: BRE_PROVIDER_DATA,
    deliveryOrigin: {
      line1: '1920 Lafayette St STE L',
      line2: null,
      city: 'Santa Clara',
      state: 'CA',
      zip: '95050',
      lat: 37.35987602299443,
      long: -121.94892120845293,
    },
    deliveryStart: () => {
      const now = datetime(Date.now(), TimeZone.US_CENTRAL);
      const friday = now.startOf('day').set({ weekday: 5, hour: 15 });

      return friday.plus({ weeks: now >= friday ? 1 : 0 });
    },
    isAddressValid: (address) => {
      if (address.zip) {
        const zipcodes = BRE_TRAVEL_FEES.flatMap(({ zipcodes }) => zipcodes);

        if (zipcodes.some((zip) => address.zip === zip)) {
          return { valid: true };
        }

        return {
          valid: false,
          message: dedent`
            The selected address is outside our delivery range. Please call our
            office to discuss a special request.
          `,
        };
      }

      return {
        valid: false,
        message: 'Please select an address with a valid postal code.',
      };
    },
    forms: {
      orderConfigure: BreOrderForm,
      wizardType: BreOrderWizardType,
      customer: BreCustomerFrom,
      provider: EmptyForm,
      times: BreOrderTimes,
    },
    performables: {
      [BreWebsiteConfig.id]: {
        config: BreWebsiteConfig,
        job: BreWebsiteJob,
        form: BreWebsiteForm,
      },
      [BrePhotosConfig.id]: {
        config: BrePhotosConfig,
        job: BrePhotosJob,
        form: BrePhotosForm,
        submit: BrePhotosSubmitForm,
      },
      [BreTwilightConfig.id]: {
        config: BreTwilightConfig,
        job: BreTwilightJob,
        form: BreTwilightForm,
      },
      [BreFloorplanConfig.id]: {
        config: BreFloorplanConfig,
        job: BreFloorplanJob,
        form: BreFloorplanForm,
      },
      [BreMatterportConfig.id]: {
        config: BreMatterportConfig,
        job: BreMatterportJob,
        form: BreMatterportForm,
        submit: BreMatterportSubmitForm,
      },
      [BreAerialConfig.id]: {
        config: BreAerialConfig,
        job: BreAerialJob,
        form: BreAerialForm,
        submit: BreAerialSubmitForm,
      },
      [BreFpvVideoConfig.id]: {
        config: BreFpvVideoConfig,
        job: BreFpvVideoJob,
        form: BreFpvVideoForm,
      },
      [BreSocialConfig.id]: {
        config: BreSocialConfig,
        job: BreSocialJob,
        form: BreSocialForm,
        submit: BreSocialSubmitForm,
      },
      [BreCinematicConfig.id]: {
        config: BreCinematicConfig,
        job: BreCinematicJob,
        form: BreCinematicForm,
        submit: BreCinematicSubmitForm,
      },
      [BreStagingConfig.id]: {
        config: BreStagingConfig,
        job: BreStagingJob,
        form: BreStagingForm,
      },
      [BreDuskConfig.id]: {
        config: BreDuskConfig,
        job: BreDuskJob,
        form: BreDuskForm,
      },
      [BreSlideshowConfig.id]: {
        config: BreSlideshowConfig,
        job: BreSlideshowJob,
        form: BreSlideshowForm,
      },
      [BreFlyersConfig.id]: {
        config: BreFlyersConfig,
        job: BreFlyersJob,
        form: BreFlyersForm,
      },
      [BreBrochuresConfig.id]: {
        config: BreBrochuresConfig,
        job: BreBrochuresJob,
        form: BreBrochuresForm,
      },
      [BreBookletsConfig.id]: {
        config: BreBookletsConfig,
        job: BreBookletsJob,
        form: BreBookletsForm,
      },
      [BreDesignConfig.id]: {
        config: BreDesignConfig,
        job: BreDesignJob,
        form: BreDesignForm,
      },
      [BreRidersConfig.id]: {
        config: BreRidersConfig,
        job: BreRidersJob,
        form: BreRidersForm,
      },
      [BreBoostConfig.id]: {
        config: BreBoostConfig,
        job: BreBoostJob,
        form: BreBoostForm,
      },
      [BrePostcardsMailingConfig.id]: {
        config: BrePostcardsMailingConfig,
        job: BrePostcardsMailingJob,
        form: BrePostcardsMailingForm,
      },
      [BrePostcardsPrintOnlyConfig.id]: {
        config: BrePostcardsPrintOnlyConfig,
        job: BrePostcardsPrintOnlyJob,
        form: BrePostcardsPrintOnlyForm,
      },
      [BrePortraitsConfig.id]: {
        config: BrePortraitsConfig,
        job: BrePortraitsJob,
        form: BrePortraitsForm,
      },
      [BreLetterheadsConfig.id]: {
        config: BreLetterheadsConfig,
        job: BreLetterheadsJob,
        form: BreLetterheadsForm,
      },
      [BreEnvelopesConfig.id]: {
        config: BreEnvelopesConfig,
        job: BreEnvelopesJob,
        form: BreEnvelopesForm,
      },
      [BreBusinessCardsConfig.id]: {
        config: BreBusinessCardsConfig,
        job: BreBusinessCardsJob,
        form: BreBusinessCardsForm,
      },
      [BreGuidesConfig.id]: {
        config: BreGuidesConfig,
        job: BreGuidesJob,
        form: BreGuidesForm,
      },
      [BreFoldersConfig.id]: {
        config: BreFoldersConfig,
        job: BreFoldersJob,
        form: BreFoldersForm,
      },
      [BreCoversConfig.id]: {
        config: BreCoversConfig,
        job: BreCoversJob,
        form: BreCoversForm,
      },
      [BreSignsConfig.id]: {
        config: BreSignsConfig,
        job: BreSignsJob,
        form: BreSignsForm,
      },
      [BreCustomConfig.id]: {
        config: BreCustomConfig,
        job: BreCustomJob,
        form: BreCustomForm,
      },
    },
  });
}
