import { BreOrderType } from '~/tenants/bre/model/BreOrderContext';

const BaseRates = {
  DUSK_PHOTO: '32',
};

export const BreDuskRate: Record<BreOrderType, typeof BaseRates> = {
  [BreOrderType.COMMERCIAL]: BaseRates,
  [BreOrderType.RENTAL]: BaseRates,
  [BreOrderType.RESIDENTIAL]: BaseRates,
  [BreOrderType.PROPERTY_MKTG]: BaseRates,
  [BreOrderType.AGENT_MKTG]: BaseRates,
};
