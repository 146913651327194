import { match } from 'ts-pattern';
import { FormHorizontal } from '~/components/form/layout';
import { InputCardOption } from '~/components/input/InputCards';
import ZodFieldCards from '~/components/zod/ZodFieldCards';
import { BreOrderType } from '~/tenants/bre/model/BreOrderContext';
import { typeSchema } from '~/tenants/bre/performable/aerial/BreAerialConfig';
import { BreAerialRate } from '~/tenants/bre/performable/aerial/BreAerialData';
import BreAerialJob from '~/tenants/bre/performable/aerial/BreAerialJob';

interface BreAerialFieldsProps {
  type: BreOrderType;
}

export default function BreAerialFields({ type }: BreAerialFieldsProps) {
  const { COMBO, FIVE, TEN, VIDEO } = BreAerialRate[type];

  const typeOptions = typeSchema.options.map<InputCardOption>((value) => ({
    title: BreAerialJob.typeText(value),
    cost: match(value)
      .with('five', () => FIVE)
      .with('ten', () => TEN)
      .with('combo', () => COMBO)
      .with('video', () => VIDEO)
      .exhaustive(),
    value,
  }));

  return (
    <FormHorizontal name="type" label="Aerial Package" required>
      <ZodFieldCards name="type" cols={2} options={typeOptions} required />
    </FormHorizontal>
  );
}
