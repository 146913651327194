import FormGroup from '~/components/form/FormGroup';
import { FormHorizontal } from '~/components/form/layout';
import ZodFieldHidden from '~/components/zod/ZodFieldHidden';
import ZodFieldSelect from '~/components/zod/ZodFieldSelect';
import ZodFieldTextarea from '~/components/zod/ZodFieldTextarea';
import { useZodFormFieldSingleValue } from '~/components/zod/ZodForm';
import { TenantPerformableFormProps } from '~/tenants/common/form';
import { MpiPhotosType } from '~/tenants/mpi/performable/photos/MpiPhotosConfig';
import { MpiOrderContext, MpiOrderType } from '../../model/MpiOrder';
import { TenantPerformableAddOns } from '~/tenants/common/performable';
import MpiPhotosAddOns from './MpiPhotosAddOns';
import ZodFieldInput from '~/components/zod/ZodFieldInput';
import ZodFieldCheckbox from '~/components/zod/ZodFieldCheckbox';
import MpiPhotosExtra from './MpiPhotosExtra';

export default function MpiPhotosForm({ context, persona, create }: TenantPerformableFormProps<MpiOrderContext>) {
  const extraPhotosEnabled = useZodFormFieldSingleValue('extra_photos_enabled');
  const orderType = context.metadata.type;

  let photos = <></>;

  if (orderType === MpiOrderType.RESIDENTIAL) {
    photos = (
      <FormHorizontal name="photos" label="Photos">
        <ZodFieldInput name="photos" default="36" disabled />
      </FormHorizontal>
    );
  }

  return (
    <FormGroup>
      <ZodFieldHidden name="type" value={MpiPhotosType.STANDARD} />
      {photos}
      <FormHorizontal name="extra_photos" label="Extra Photos">
        <MpiPhotosExtra />
      </FormHorizontal>
      <FormHorizontal label="Special Instructions">
        <ZodFieldTextarea name="instructions" />
      </FormHorizontal>
      <TenantPerformableAddOns persona={persona}>
        <MpiPhotosAddOns persona={persona} context={context} create={create} />
      </TenantPerformableAddOns>
    </FormGroup>
  );
}
