import { TenantPackageConfig } from '~/tenants/common/registry';
import TwtPhotoConfig from '~/tenants/twt/performable/photo/TwtPhotoConfig';
import TwtFloorplanConfig from '~/tenants/twt/performable/floorplan/TwtFloorplanConfig';
import { TwtOrderContext, TwtPropertyType } from '~/tenants/twt/model/TwtOrder';
import { TwtOrderType } from '~/tenants/twt/model/enums';

const TwtFloorplanTourConfig: TenantPackageConfig = {
  id: 'floorplan',
  name: 'Photo + Floor Plan Tour',
  description:
    'Market a property with HDR photography and a stylized floor plan organized into a customized microsite.',
  performables: [TwtPhotoConfig, TwtFloorplanConfig],
  eligible: (context) =>
    (context as TwtOrderContext).metadata.type === TwtOrderType.FOR_SALE_LISTING &&
    (context as TwtOrderContext).metadata.subtype !== TwtPropertyType.LAND,
};

export default TwtFloorplanTourConfig;
