import Big from 'big.js';
import BreJob from '~/tenants/bre/model/BreJob';
import BreCinematicConfig from '~/tenants/bre/performable/cinematic/BreCinematicConfig';
import { TenantJobLine } from '~/lib/model';
import { DeliverableType } from '~common/model/Deliverable';
import { BreOrderType } from '~/tenants/bre/model/BreOrderContext';
import { FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata } from '~/lib/zod';
import { match } from 'ts-pattern';
import { PersonaType } from '~/tenants/common/TenantPersona';
import { MicrositeFileType } from '~microsite/lib/types';
import { BreCinematicRate } from '~/tenants/bre/performable/cinematic/BreCinematicData';

export default class BreCinematicJob extends BreJob<typeof BreCinematicConfig> {
  get performable() {
    return BreCinematicConfig;
  }

  get configurable(): boolean {
    return true;
  }

  get submission(): boolean {
    return true;
  }

  get deliverable(): DeliverableType {
    return DeliverableType.VIDEO;
  }

  get editing() {
    return !this.metadata.self_edited;
  }

  get media() {
    return {
      persona: this.editing ? PersonaType.EDITOR : PersonaType.PROVIDER,
      microsite: MicrositeFileType.VIDEO,
    };
  }

  get eligible(): boolean {
    return !this.isMarketing;
  }

  get videoRevenue(): Big {
    const { type } = this.order.metadata;
    const { aerial_upgrade, duration } = this.metadata;

    if (this.sqft > 0) {
      const isCommercial = type === BreOrderType.COMMERCIAL;

      if (aerial_upgrade) {
        return isCommercial && duration
          ? new Big('656').times(duration).sub('198')
          : new Big('0.22').times(this.sqft).add('456');
      }

      return isCommercial && duration
        ? new Big('656').times(duration).sub('565')
        : new Big('0.21').times(this.sqft).add('209');
    }

    return new Big('0');
  }

  defaultValue(): ZodVersionedMetadata<(typeof BreCinematicConfig)['schema']> {
    return {
      version: FIRST_VERSION_TIMESTAMP,
    };
  }

  onsite(): number {
    const { aerial_photos, aerial_upgrade } = this.metadata;

    if (this.sqft > 0) {
      const video = match(this.sqft)
        .when(
          (sqft) => sqft < 1500,
          () => 60,
        )
        .when(
          (sqft) => sqft < 2500,
          () => 75,
        )
        .when(
          (sqft) => sqft < 3000,
          () => 85,
        )
        .when(
          (sqft) => sqft < 5000,
          () => 90,
        )
        .when(
          (sqft) => sqft < 6500,
          () => 120,
        )
        .when(
          (sqft) => sqft < 8000,
          () => 150,
        )
        .otherwise(() => 180);
      const aerial = aerial_upgrade || aerial_photos ? 30 : 0;

      return video + aerial;
    }

    return 0;
  }

  revenueLines(): TenantJobLine[] {
    const { aerial_photos, aerial_upgrade, intro, kwasi, narration, rush, twilight } = this.metadata;
    const { AERIAL_PHOTOS, INTRO, KWASI, NARRATION, RUSH, TWILIGHT } = BreCinematicRate[this.order.metadata.type];
    const lines: TenantJobLine[] = [];

    if (this.sqft > 0) {
      lines.push({
        id: 'video',
        description: `Cinematic Film${aerial_upgrade ? ' (with aerial)' : ''}`,
        amount: this.videoRevenue,
      });
    }

    if (intro) {
      lines.push({
        id: 'intro',
        description: 'Agent Intro',
        amount: new Big(INTRO),
      });
    }

    if (narration) {
      lines.push({
        id: 'narration',
        description: 'Agent Narration',
        amount: new Big(NARRATION),
      });

      if (kwasi) {
        lines.push({
          id: 'kwasi',
          description: `Professional Voice-Over ${kwasi}`,
          amount: new Big(KWASI),
        });
      }
    }

    if (twilight) {
      lines.push({
        id: 'twilight',
        description: 'Twilight Transitions',
        amount: new Big(TWILIGHT),
      });
    }

    if (aerial_photos) {
      lines.push({
        id: 'aerial_photos',
        description: '5 Aerial Photos',
        amount: new Big(AERIAL_PHOTOS),
      });
    }

    if (rush) {
      lines.push({
        id: 'rush_fee',
        description: 'Next-day rush fee',
        amount: new Big(RUSH),
      });
    }

    return lines;
  }

  expenseLines(): TenantJobLine[] {
    const { aerial_photos, aerial_upgrade, intro, kwasi, narration, self_edited, twilight } = this.metadata;
    const lines: TenantJobLine[] = [];

    if (this.sqft > 0) {
      lines.push({
        id: 'video',
        description: `Cinematic Film${aerial_upgrade ? ' (with aerial)' : ''}`,
        amount: this.videoRevenue.div('1.05').times(self_edited ? '0.5' : '0.7'),
      });
    }

    if (intro) {
      lines.push({
        id: 'intro',
        description: 'Agent Intro',
        amount: new Big('88'),
      });
    }

    if (narration) {
      lines.push({
        id: 'narration',
        description: 'Agent Narration',
        amount: new Big('125'),
      });

      if (kwasi) {
        lines.push({
          id: 'kwasi',
          description: `Professional Voice-Over (${kwasi})`,
          amount: new Big('125'),
        });
      }
    }

    if (twilight) {
      lines.push({
        id: 'twilight',
        description: 'Twilight Transitions',
        amount: new Big('200'),
      });
    }

    if (aerial_photos) {
      lines.push({
        id: 'aerial_photos',
        description: '5 Aerial Photos',
        amount: new Big('65'),
      });
    }

    return lines;
  }
}
