import { useState } from 'react';
import { useZodFormFieldSingleValue } from '~/components/zod/ZodForm';
import { FormHelperView, FormHorizontal } from '~/components/form/layout';
import ZodFieldInput from '~/components/zod/ZodFieldInput';
import InputCheckbox from '~/components/input/InputCheckbox';
import { format_money } from '~/components/Money';
import MpiPhotosJob from './MpiPhotosJob';
import ZodFieldSelect from '~/components/zod/ZodFieldSelect';

export default function MpiPhotosExtra() {
  const extraPhotos = Number(useZodFormFieldSingleValue('extra_photos') ?? 0);

  const [agree, setAgree] = useState(false);

  return (
    <div className="flex flex-col gap-2">
      <div className="flex justify-between">
        <label className="flex gap-2 font-medium cursor-pointer">
          <InputCheckbox checked={agree} onChange={setAgree} />
          Want extra photos?
        </label>

        {agree && (
          <p className="font-bold whitespace-nowrap">
            Up to {format_money(MpiPhotosJob.extraPhotos(extraPhotos).toFixed(2))}
          </p>
        )}
      </div>
      <p className="text-sm text-gray-600">
        By checking this box, you are allowing our photographers to take additional photos using their professional
        discretion, and you accept the additional fees for extra images.
      </p>
      {agree && (
        <FormHorizontal name="extra_photos" label="Max Photos">
          <ZodFieldSelect
            options={[
              { label: '5 Photos', value: '5' },
              { label: '10 Photos', value: '10' },
              { label: '15 Photos', value: '15' },
              { label: '20 Photos', value: '20' },
              { label: '25 Photos', value: '25' },
              { label: '30 Photos', value: '30' },
              { label: '35 Photos', value: '35' },
              { label: '40 Photos', value: '40' },
            ]}
            name="photos"
          />
        </FormHorizontal>
      )}
    </div>
  );
}
