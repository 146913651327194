import { BreOrderType } from '~/tenants/bre/model/BreOrderContext';

const BaseRates = {
  OUTDOOR: '200',
  OUTDOOR_XL: '400',
};

export const BreMatterportRate: Record<BreOrderType, typeof BaseRates> = {
  [BreOrderType.COMMERCIAL]: {
    ...BaseRates,
    OUTDOOR: '750',
    OUTDOOR_XL: '1250',
  },
  [BreOrderType.RENTAL]: BaseRates,
  [BreOrderType.RESIDENTIAL]: BaseRates,
  [BreOrderType.PROPERTY_MKTG]: BaseRates,
  [BreOrderType.AGENT_MKTG]: BaseRates,
};
