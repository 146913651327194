import FormGroup from '~/components/form/FormGroup';
import { FormHorizontal } from '~/components/form/layout';
import { useTz } from '~/components/hooks/useTz';
import { InputCardOption } from '~/components/input/InputCards';
import { InputSelectOption } from '~/components/input/InputSelect';
import ZodFieldCheckbox from '~/components/zod/ZodFieldCheckbox';
import ZodFieldDate from '~/components/zod/ZodFieldDate';
import ZodFieldCards from '~/components/zod/ZodFieldCards';
import ZodFieldSelect from '~/components/zod/ZodFieldSelect';
import { useZodFormFieldSingleValue } from '~/components/zod/ZodForm';
import { isBusinessDay, plusBusinessDays } from '~/lib/datettime';
import { capitalize } from '~/lib/format';
import { BreOrderContext } from '~/tenants/bre/model/BreOrderContext';
import { CONTENT_WRITING_RATE, toNeedBy } from '~/tenants/bre/performable/common';
import BreDelivery from '~/tenants/bre/performable/print/BreDelivery';
import {
  BookletPaper,
  BookletSide,
  countSchema,
  paperSchema,
  sideSchema,
} from '~/tenants/bre/performable/print/booklets/BreBookletsConfig';
import { BreBookletsRate } from '~/tenants/bre/performable/print/booklets/BreBookletsData';
import BreBookletsJob from '~/tenants/bre/performable/print/booklets/BreBookletsJob';
import { layoutSchema } from '~/tenants/bre/performable/print/design/BreDesignConfig';
import { BreDesignRate } from '~/tenants/bre/performable/print/design/BreDesignData';
import { TenantPerformableFormProps } from '~/tenants/common/form';
import { TenantPackageAddOnCheckbox, TenantPackageAddOnRadioCards } from '~/tenants/common/package';
import { TenantPerformableAddOns } from '~/tenants/common/performable';

const SIDE_OPTS = sideSchema.options.map<InputCardOption>((value) => ({
  title: `${value} Pages`,
  description: 'booklet',
  value,
}));

const COUNT_OPTS = countSchema.options.map<InputCardOption>((value) => ({
  title: `${value} pieces`,
  description: 'print',
  value,
}));

export default function BreBookletsForm({ context, persona }: TenantPerformableFormProps<BreOrderContext>) {
  const tz = useTz();
  const sides = useZodFormFieldSingleValue<BookletSide>('sides') ?? '8';
  const paper = useZodFormFieldSingleValue<BookletPaper>('paper');
  const needBy = useZodFormFieldSingleValue('need_by', toNeedBy);

  const paperOptions = paperSchema.options
    .filter((value) => BreBookletsRate[needBy][sides]?.[value])
    .map<InputSelectOption>((value) => ({
      label: BreBookletsJob.pageType({ paper: value }),
      value,
    }));

  const designOptions = layoutSchema.options.map<InputCardOption>((value) => {
    const rate = BreDesignRate[value][`brochure_${sides}`];

    return {
      title: `${capitalize(value)} Layout`,
      cost: rate,
      disabled: !rate,
      value,
    };
  });

  return (
    <FormGroup>
      <FormHorizontal name="sides" label="Sides" required>
        <ZodFieldCards name="sides" options={SIDE_OPTS} required />
      </FormHorizontal>
      <FormHorizontal name="count" label="Count" required>
        <ZodFieldCards name="count" options={COUNT_OPTS} cols={2} required />
      </FormHorizontal>
      <FormHorizontal name="paper" label="Paper" required>
        <ZodFieldSelect name="paper" options={paperOptions} />
      </FormHorizontal>
      {paper !== 'heavy_low_gloss' && (
        <FormHorizontal name="no_free_uv" label="UV Cover">
          <label className="flex gap-2 cursor-pointer" htmlFor="no_free_uv">
            <ZodFieldCheckbox id="no_free_uv" name="no_free_uv" />
            Opt-out of free UV cover
          </label>
        </FormHorizontal>
      )}
      <FormHorizontal name={'need_by'} label="Need By" required>
        <ZodFieldDate
          name="need_by"
          filter={(date) => isBusinessDay(date) && date >= plusBusinessDays(tz().startOf('day'), 2)}
        />
      </FormHorizontal>
      <BreDelivery name="delivery" label="Delivery" context={context} required />

      <TenantPerformableAddOns persona={persona}>
        <TenantPackageAddOnCheckbox
          name="content_writing"
          title="Content writing"
          description="Add content writing for all of your marketing pieces"
          image="/tenant/bre/print/content_writing.jpg"
          persona={persona}
          cost={CONTENT_WRITING_RATE}
        />
        <TenantPackageAddOnRadioCards
          name="design"
          title="Design"
          description="Include layout design to your flyers"
          image="" // ? what image should we use here
          persona={persona}
          options={designOptions}
          cols={2}
          required
        />
      </TenantPerformableAddOns>
    </FormGroup>
  );
}
