import Message from '~/components/Message';

export default function BreListingDisclosure() {
  return (
    <Message type="info">
      The property must be "Photo Ready" upon the photographer's arrival. If not, a same-day cancellation or delay fee
      will apply:
      <ul className="list-disc list-inside indent-2">
        <li>
          <b>Same-Day Cancellation:</b> $125 (less than 24 hours notice) or $175 (less than 3 hours notice).
        </li>
        <li>
          <b>Delay Fee:</b> $50 for every 15 minutes of delay (up to 30 minutes).
        </li>
      </ul>
      <div className="pt-2">Please ensure the property is prepared in advance to avoid additional fees.</div>
    </Message>
  );
}
