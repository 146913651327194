import { ReactNode } from 'react';
import { TenantPackageConfig } from '~/tenants/common/registry';
import TwtJonnyConfig from '~/tenants/twt/package/forsale/jonny/TwtJonnyConfig';
import TwtMatterportTourConfig from '~/tenants/twt/package/forsale/matterport/TwtMatterportTourConfig';
import TwtMatterportTourForm from '~/tenants/twt/package/forsale/matterport/TwtMatterportTourForm';
import TwtFloorplanTourConfig from '~/tenants/twt/package/forsale/floorplan/TwtFloorplanTourConfig';
import TwtFloorplanTourForm from '~/tenants/twt/package/forsale/floorplan/TwtFloorplanTourForm';
import TwtRentalFloorplanConfig from '~/tenants/twt/package/rental/floorplan/TwtRentalFloorplanConfig';
import TwtRentalFloorplanForm from '~/tenants/twt/package/rental/floorplan/TwtRentalFloorplanForm';
import TwtRentalAerialConfig from '~/tenants/twt/package/rental/aerial/TwtRentalAerialConfig';
import TwtRentalAerialForm from '~/tenants/twt/package/rental/aerial/TwtRentalAerialForm';
import TwtJonnyForm from '~/tenants/twt/package/forsale/jonny/TwtJonnyForm';
import TwtJustinConfig from '~/tenants/twt/package/forsale/justin/TwtJustinConfig';
import TwtJustinForm from '~/tenants/twt/package/forsale/justin/TwtJustinForm';

const TWT_PACKAGES: Array<{ config: TenantPackageConfig; form: () => ReactNode }> = [
  {
    config: TwtJonnyConfig,
    form: TwtJonnyForm,
  },
  {
    config: TwtJustinConfig,
    form: TwtJustinForm,
  },
  {
    config: TwtMatterportTourConfig,
    form: TwtMatterportTourForm,
  },
  {
    config: TwtFloorplanTourConfig,
    form: TwtFloorplanTourForm,
  },
  {
    config: TwtRentalFloorplanConfig,
    form: TwtRentalFloorplanForm,
  },
  {
    config: TwtRentalAerialConfig,
    form: TwtRentalAerialForm,
  },
];

export default TWT_PACKAGES;
