import Big from 'big.js';
import { match } from 'ts-pattern';
import { TenantJobLine } from '~/lib/model';
import { FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata } from '~/lib/zod';
import BreJob from '~/tenants/bre/model/BreJob';
import BreStagingConfig from '~/tenants/bre/performable/staging/BreStagingConfig';
import { BreStagingRate } from '~/tenants/bre/performable/staging/BreStagingData';
import { PersonaType } from '~/tenants/common/TenantPersona';
import { DeliverableType } from '~common/model/Deliverable';
import { MicrositeFileType } from '~microsite/lib/types';

export default class BreStagingJob extends BreJob<typeof BreStagingConfig> {
  get performable() {
    return BreStagingConfig;
  }

  get configurable(): boolean {
    return true;
  }

  get deliverable(): DeliverableType {
    return DeliverableType.PHOTO;
  }

  get media() {
    return {
      persona: PersonaType.PROVIDER,
      microsite: MicrositeFileType.GALLERY,
    };
  }

  get eligible(): boolean {
    return !this.isMarketing;
  }

  defaultValue(): ZodVersionedMetadata<(typeof BreStagingConfig)['schema']> {
    return {
      version: FIRST_VERSION_TIMESTAMP,
      style: 'modern',
      quantity: 1,
    };
  }

  revenueLines(): TenantJobLine[] {
    const { quantity, item_removal, space_renovation } = this.metadata;
    const { TIER_1, TIER_2, TIER_3, TIER_4, ITEM_REMOVAL, SPACE_RENOVATION } = BreStagingRate[this.order.metadata.type];
    const lines: TenantJobLine[] = [];
    const tier = match(quantity)
      .when(
        (q) => q <= 3,
        () => TIER_1,
      )
      .when(
        (q) => q <= 6,
        () => TIER_2,
      )
      .when(
        (q) => q <= 9,
        () => TIER_3,
      )
      .otherwise(() => TIER_4);

    lines.push({
      id: 'virtual_staging',
      description: `x${quantity} Virtual Staging`,
      amount: new Big(tier).times(quantity),
    });

    if (item_removal) {
      lines.push({
        id: 'item_removal',
        description: `x${item_removal} Items Removal`,
        amount: new Big(ITEM_REMOVAL).times(item_removal),
      });
    }

    if (space_renovation) {
      lines.push({
        id: 'space_renovation',
        description: `x${space_renovation} Space Renovations`,
        amount: new Big(SPACE_RENOVATION).times(space_renovation),
      });
    }

    return lines;
  }
}
