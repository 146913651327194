import { BreOrderType } from '~/tenants/bre/model/BreOrderContext';
import OrderWizardTypeCard from '~/tenants/common/forms/OrderWizardTypeCard';

export default function BreOrderWizardType() {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
      <OrderWizardTypeCard
        value={BreOrderType.RESIDENTIAL}
        image="tenant/bre/order_residential.jpg"
        title="Residential"
      >
        Residential listings including single family homes, condominiums, townhomes, etc.
      </OrderWizardTypeCard>

      <OrderWizardTypeCard value={BreOrderType.COMMERCIAL} image="tenant/bre/order_commercial.jpg" title="Commercial">
        Commercial listing.
      </OrderWizardTypeCard>

      <OrderWizardTypeCard value={BreOrderType.RENTAL} image="tenant/bre/order_rental.jpg" title="Rental/Airbnb">
        Lease listings including single family homes, condominiums, townhomes, etc.
      </OrderWizardTypeCard>

      <OrderWizardTypeCard
        value={BreOrderType.PROPERTY_MKTG}
        image="tenant/bre/order_property_mktg.jpg"
        title="Property Marketing"
      >
        Property marketing materials.
      </OrderWizardTypeCard>

      <OrderWizardTypeCard
        value={BreOrderType.AGENT_MKTG}
        image="tenant/bre/order_agent_mktg.jpg"
        title="Agent Marketing"
      >
        Agent marketing materials.
      </OrderWizardTypeCard>
    </div>
  );
}
