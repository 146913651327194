import z from 'zod';
import { createPerformableConfig, TenantId } from '~/tenants/common/registry';
import { coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP, zodTDateISODate } from '~/lib/zod';
import { deliverySchema } from '~/tenants/bre/performable/common';
import dedent from 'dedent';

export type LetterheadCount = z.infer<typeof countSchema>;

export type LetterheadPaper = z.infer<typeof paperSchema>;

export const countSchema = z.enum([
  '250',
  '500',
  '1000',
  '2500',
]);

export const paperSchema = z.enum(['smooth_white', 'premium_linen_white']);

export default createPerformableConfig(
  'letterheads',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
      count: countSchema,
      paper: paperSchema,
      need_by: zodTDateISODate(),
      delivery: deliverySchema,
    }),
  },
  {
    name: 'Letterheads',
    group: 'Agent Marketing',
    tenant: TenantId.BEYOND_RE_MARKETING,
    thumbnail: '/tenant/bre/print/letterheads/thumbnail.jpg',
    images: [],
    short: dedent`
      We offer professional letterhead printing on your choice of Smooth White \
      or Premium Linen White paper, delivering a polished and elegant look for \
      your business communications.
    `,
  },
);
