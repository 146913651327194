import { useState } from 'react';
import FormGroup from '~/components/form/FormGroup';
import { FormHorizontal } from '~/components/form/layout';
import IconSolidNew from '~/components/icons/streamline/solid/IconSolidNew';
import InputText from '~/components/input/InputText';
import Button from '~/components/interactive/Button';
import IconText from '~/components/layout/IconText';
import ZodFieldAddress from '~/components/zod/ZodFieldAddress';
import { ZodNestedForm, useZodFormFieldObject } from '~/components/zod/ZodForm';
import { DistanceAddress } from '~/lib/model';
import { useTenant } from '~/tenants/common/TenantContextProvider';
import { TenantCustomerFormProps } from '~/tenants/common/form';

interface Line2Props {
  name: string;
}

export default function BreCustomerFrom(props: TenantCustomerFormProps) {
  const tenant = useTenant();

  return (
    <FormGroup>
      <FormHorizontal name="office" label="Office Address">
        <ZodFieldAddress name="office" distance={tenant.deliveryOrigin} start={tenant.deliveryStart?.()} editable />
        <Line2 name="office" />
      </FormHorizontal>
      <FormHorizontal name="home" label="Home Address">
        <ZodFieldAddress name="home" distance={tenant.deliveryOrigin} start={tenant.deliveryStart?.()} editable />
        <Line2 name="home" />
      </FormHorizontal>
    </FormGroup>
  );
}

function Line2({ name }: Line2Props) {
  const [_, address, setAddress] = useZodFormFieldObject<DistanceAddress>(name);

  const [show, setShow] = useState(Boolean(address?.line2));

  if (!address?.line1) {
    return null;
  }

  return (
    <ZodNestedForm name={name}>
      {!show && (
        <>
          <IconText icon={<IconSolidNew />} text="Add Line 2" onClick={() => setShow(true)} />
        </>
      )}

      {show && (
        <div className="py-2 flex items-center max-w-md space-x-2">
          <InputText
            placeholder="Apt. Number, Building, etc..."
            onChange={(line2) => setAddress({ ...address, line2 })}
            value={address.line2 ?? ''}
            autoFocus
          />
          <Button
            variant="outline"
            onClick={() => {
              setAddress({ ...address, line2: null });
              setShow(false);
            }}
          >
            Remove
          </Button>
        </div>
      )}
    </ZodNestedForm>
  );
}
