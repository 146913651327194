import z from 'zod';
import { FIRST_VERSION_TIMESTAMP, coerceLiteralNumberRequired, zodTDateISODate } from '~/lib/zod';
import { TenantId, createPerformableConfig } from '~/tenants/common/registry';

export type GuideType = z.infer<typeof typeSchema>;

export const typeSchema = z.enum([
  'selling',
  'buyers',
  'after_sale',
  'preparing_for_sale',
  'contract_to_close',
]);

export default createPerformableConfig(
  'guides',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
      types: typeSchema.array().min(1).max(typeSchema.options.length),
      need_by: zodTDateISODate(),
    }),
  },
  {
    name: 'PDF Agent/Broker Guides',
    group: 'Agent Marketing',
    images: [],
    tenant: TenantId.BEYOND_RE_MARKETING,
    short: 'Digital design-only guide...',
  },
);
