import { useEffect } from 'react';
import InputCards, { InputCardsProps } from '~/components/input/InputCards';
import { useZodFormFieldMultiple, useZodFormFieldSingle } from '~/components/zod/ZodForm';

interface ZodFieldCardsBaseProps extends Omit<InputCardsProps, 'onChange' | 'value'> {
  name: string;
}

interface ZodFieldCardsSingleProps extends ZodFieldCardsBaseProps {
  multiple?: false;
  default?: string;
}

interface ZodFieldCardsMultiProps extends ZodFieldCardsBaseProps {
  multiple: true;
  default?: string[];
}

type ZodFieldCardsProps = ZodFieldCardsSingleProps | ZodFieldCardsMultiProps;

export default function ZodFieldCards(props: ZodFieldCardsProps) {
  return props.multiple ? <ZodFieldCardsMulti {...props} /> : <ZodFieldCardsSingle {...props} />;
}

function ZodFieldCardsSingle({ name, ...props }: ZodFieldCardsSingleProps) {
  const [prefixed, value, setValue] = useZodFormFieldSingle(name);

  useEffect(() => {
    if (props.default && value === undefined) {
      setValue(props.default);
    }
  }, []);

  return <InputCards {...props} multiple={false} name={prefixed} onChange={setValue} value={value} />;
}

function ZodFieldCardsMulti({ name, ...props }: ZodFieldCardsMultiProps) {
  const [prefixed, value, setValue] = useZodFormFieldMultiple(name);

  useEffect(() => {
    if (props.default && value.length === 0) {
      setValue(props.default);
    }
  }, []);

  return <InputCards {...props} multiple={true} name={prefixed} onChange={setValue} value={value} />;
}
