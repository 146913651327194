import { TenantOrder } from '../../common/TenantOrder';
import { BRE_CANCEL_FEE, BRE_HOLIDAYS, BRE_HOLIDAY_FEE, BRE_TRAVEL_FEES, BRE_WEEKEND_FEE } from '../data';
import { TenantOrderLine } from '~/lib/model';
import Big from 'big.js';
import BreFlyersJob from '~/tenants/bre/performable/print/flyers/BreFlyersJob';
import BrePhotosJob from '~/tenants/bre/performable/photos/BrePhotosJob';

import { DateTime } from 'luxon';
import { BreOrderContext } from '~/tenants/bre/model/BreOrderContext';
import BreJob from '~/tenants/bre/model/BreJob';
import { BrePhotosRate } from '~/tenants/bre/performable/photos/BrePhotosData';
import { datetime, formalTime } from '~/lib/datettime';
import { holiday_lookup } from '~common/holidays/holidays';

interface FeeLineOptions {
  id: string;
  date: DateTime;
  excludeWeekendFees: boolean | undefined;
}

export class BreOrder extends TenantOrder<BreOrderContext, BreJob> {
  revenueLines(): TenantOrderLine[] {
    const { excludeTravelFees, excludeWeekendFees, type } = this.context.metadata;
    const hasSameDayFlyers = this.jobs.some((job) => job instanceof BreFlyersJob && job.needBy === 'same_day');
    const hasRushPhotos = this.jobs.some((job) => job instanceof BrePhotosJob && job.metadata.rush);
    const lines = super.revenueLines();

    if (hasSameDayFlyers && hasRushPhotos) {
      const { RUSH } = BrePhotosRate[type];

      lines.push({
        id: 'free_rush_photos',
        description: 'Same-day flyers photo rush discount',
        discount: true,
        amount: new Big(RUSH).neg(),
      });
    }

    // TODO: How to we differentiate between packages when their performables overlap?
    const pkg = this.tenant.packages.find(({ config }) => {
      const matchSize = this.jobs.length >= config.performables.length;
      const hasAllJobs = config.performables
        .map((p) => ('id' in p ? p.id : p.config.id))
        .every((id) => this.jobs.some((j) => j.performable.id === id));

      return matchSize && hasAllJobs;
    });

    if (pkg?.config.percentage) {
      const pkgJobs = this.jobs.filter((j) =>
        pkg.config.performables.map((p) => ('id' in p ? p.id : p.config.id)).includes(j.performable.id),
      );
      const discountable = pkgJobs.reduce((acc, j) => {
        return acc.plus(j.revenue(true));
      }, new Big(0));

      lines.push({
        id: 'package_discount',
        description: `${pkg.config.name} Package Discount`,
        amount: discountable.times(pkg.config.percentage).neg(),
        discount: true,
      });
    }

    if (!excludeTravelFees) {
      if (!this.hasActiveAppointments && this.jobs.some((j) => j.onsite() > 0)) {
        const { zip } = this.context.address ?? {};

        if (zip) {
          const zone = BRE_TRAVEL_FEES.find((f) => f.zipcodes.includes(zip));

          if (zone?.fee) {
            lines.push({
              id: 'travel',
              description: 'Travel Compensation',
              amount: new Big(zone.fee),
            });
          }
        }

        this.context.requested.every(({ day }) => {
          const date = datetime(day, this.location.timezone);
          const feeLine = feeLineForDate({ id: 'requested', date, excludeWeekendFees });

          if (feeLine !== null) {
            lines.push(feeLine);
            return false;
          }

          return true;
        });
      }

      this.context.appointments.forEach(({ address, id, start, canceled }) => {
        const { zip } = address ?? this.context.address ?? {};
        const date = datetime(start, this.location.timezone);

        if (canceled) {
          lines.push({
            id: `cancel-${id}`,
            description: `Cancellation fee (${formalTime(date)})`,
            amount: new Big(BRE_CANCEL_FEE[canceled]),
            appointment_id: id,
          });
        } else if (zip) {
          const zone = BRE_TRAVEL_FEES.find((f) => f.zipcodes.includes(zip));
          const feeLine = feeLineForDate({ id, date, excludeWeekendFees });

          if (zone?.fee) {
            lines.push({
              id: `travel-${id}`,
              description: `Travel Compensation (${date.toFormat('MM/dd')})`,
              amount: new Big(zone.fee),
              fee: 'travel',
            });
          }

          if (feeLine !== null) {
            lines.push(feeLine);
          }
        }
      });
    }

    return lines;
  }

  expenseLines(): TenantOrderLine[] {
    const { excludeTravelFees, excludeWeekendFees } = this.context.metadata;
    const lines = super.expenseLines();

    if (!excludeTravelFees) {
      this.context.appointments.forEach(({ id, address, start, canceled }) => {
        const { zip } = address ?? this.context.address ?? {};
        const date = datetime(start, this.location.timezone);

        if (canceled) {
          lines.push({
            id: `cancel-${id}`,
            description: `Cancellation Fee (${formalTime(date)})`,
            amount: new Big(BRE_CANCEL_FEE[canceled]).times('0.65'),
            appointment_id: id,
          });
        } else if (zip) {
          const zone = BRE_TRAVEL_FEES.find(({ zipcodes }) => zipcodes.includes(zip));
          const feeLine = feeLineForDate({ id, date, excludeWeekendFees });

          if (zone?.fee) {
            lines.push({
              id: `travel-${id}`,
              description: `Travel Compensation (${date.toFormat('MM/dd')})`,
              amount: new Big(zone.fee),
              fee: 'travel',
            });
          }

          if (feeLine !== null) {
            lines.push(feeLine);
          }
        }
      });
    }

    return lines;
  }
}

function feeLineForDate({ id, date, excludeWeekendFees }: FeeLineOptions): TenantOrderLine | null {
  const holidays = holiday_lookup(date);

  if (holidays.some((h) => BRE_HOLIDAYS.includes(h))) {
    return {
      id: `${id}-holiday`,
      description: 'Holiday Fee',
      amount: new Big(BRE_HOLIDAY_FEE),
      fee: 'holiday',
    };
  }

  if (!excludeWeekendFees && date.weekday > 5) {
    return {
      id: `${id}-weekend`,
      description: 'Weekend fee',
      amount: new Big(BRE_WEEKEND_FEE),
      fee: 'weekend',
    };
  }

  return null;
}
