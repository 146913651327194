import Big from 'big.js';
import { TenantJobLine, TenantOrderLine } from '~/lib/model';
import BreFloorplanConfig from '~/tenants/bre/performable/floorplan/BreFloorplanConfig';
import BreJob from '~/tenants/bre/model/BreJob';
import { DeliverableType } from '~common/model/Deliverable';
import { FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata } from '~/lib/zod';
import { BreOrderType } from '~/tenants/bre/model/BreOrderContext';
import { match } from 'ts-pattern';
import { PersonaType } from '~/tenants/common/TenantPersona';
import { MicrositeFileType } from '~microsite/lib/types';
import { BreFloorplanPay, BreFloorplanRate } from '~/tenants/bre/performable/floorplan/BreFloorplanData';

export default class BreFloorplanJob extends BreJob<typeof BreFloorplanConfig> {
  get performable() {
    return BreFloorplanConfig;
  }

  get configurable(): boolean {
    return true;
  }

  get deliverable(): DeliverableType {
    return DeliverableType.FLOORPLAN;
  }

  get isStandalone(): boolean {
    const { jobs } = this.order;

    return !jobs.length || (jobs.length === 1 && jobs[0].performable_id === 'floorplan');
  }

  get media() {
    return {
      persona: PersonaType.PROVIDER,
      microsite: MicrositeFileType.FLOORPLAN,
    };
  }

  get eligible(): boolean {
    return !this.isMarketing;
  }

  static rate(sqft: number, type: BreOrderType): Big {
    const { BASELINE, SQFT } = BreFloorplanRate[type];
    return new Big(SQFT).times(sqft).add(BASELINE);
  }

  static pay(sqft: number, type: BreOrderType): Big {
    const { BASELINE, SQFT } = BreFloorplanPay[type];
    return new Big(SQFT).times(sqft).add(BASELINE);
  }

  defaultValue(): ZodVersionedMetadata<(typeof BreFloorplanConfig)['schema']> {
    return {
      version: FIRST_VERSION_TIMESTAMP,
    };
  }

  onsite(): number {
    if (this.sqft > 0) {
      return match(this.sqft)
        .when(
          (sqft) => sqft <= 3000,
          () => 10,
        )
        .when(
          (sqft) => sqft <= 5000,
          () => 15,
        )
        .when(
          (sqft) => sqft <= 8000,
          () => 25,
        )
        .otherwise(() => 30);
    }

    return 0;
  }

  revenueLines(): TenantOrderLine[] {
    const { type } = this.order.metadata;
    const { RUSH, STANDALONE } = BreFloorplanRate[type];
    const lines: TenantOrderLine[] = [];

    if (this.sqft > 0) {
      const rate = BreFloorplanJob.rate(this.sqft, type);
      const amount = this.isStandalone ? rate.plus(STANDALONE) : rate;

      lines.push({
        id: '2d_floorplan',
        description: `${this.isStandalone ? 'Standalone ' : ''}2D Floorplan`,
        amount,
        discountable: true,
      });
    }

    if (this.metadata.rush) {
      lines.push({
        id: 'rush_fee',
        description: 'Before noon rush fee',
        amount: new Big(RUSH),
      });
    }

    return lines;
  }

  expenseLines(): TenantJobLine[] {
    const { type } = this.order.metadata;
    const { STANDALONE } = BreFloorplanPay[type];
    const lines: TenantJobLine[] = [];

    if (this.sqft > 0) {
      const pay = BreFloorplanJob.pay(this.sqft, type);

      lines.push({
        id: 'floorplan',
        description: `${this.isStandalone ? 'Standalone ' : ''}2D Floorplan`,
        amount: this.isStandalone ? pay.plus(STANDALONE) : pay,
      });
    }

    return lines;
  }
}
