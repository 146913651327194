import { AppointmentCancel } from '~/lib/enum';
import { ProviderStaticConfig } from '~/lib/model';
import { BreProviderId, BreProviderStatic } from '~/tenants/bre/model/BreProvider';
import { Holiday } from '~common/holidays/holidays';

export const BRE_HOLIDAYS = [
  Holiday.MEMORIAL_DAY,
  Holiday.JULY_4TH,
  Holiday.LABOR_DAY,
  Holiday.THANKSGIVING,
  Holiday.CHRISTMAS_EVE,
  Holiday.CHRISTMAS,
  Holiday.NEW_YEARS_EVE,
  Holiday.NEW_YEARS,
];

export const BRE_HOLIDAY_FEE = '100';

export const BRE_WEEKEND_FEE = '100';

export const BRE_CANCEL_FEE: Record<AppointmentCancel, string> = {
  [AppointmentCancel.SAME_DAY]: '125',
  [AppointmentCancel.ENROUTE]: '175',
  [AppointmentCancel.ONSITE]: '200',
};

export const BRE_PROVIDER_DATA: Record<BreProviderId, BreProviderStatic & ProviderStaticConfig> = {
  [BreProviderId.JOSEPH_IRASUSTA]: {
    first: 'Joseph',
    last: 'Irasusta',
    color: '#3B5998',
    email: 'joseph.irasusta0416@gmail.com',
    headshot: 'avatar.jpg',
    phone: '+15159749848',
    schedule_start: '04:00',
    schedule_end: '22:00',
    location: 'sjc',
    start_address: {
      line1: '3900 Horner Street',
      city: 'Union City',
      state: 'CA',
      zip: '94587',
      long: -122.0793398,
      lat: 37.5944011,
    },
    performables: {
      photos: true,
      floorplan: true,
      social: true,
      cinematic: true,
      aerial: true,
    },
  },
  [BreProviderId.KWASI_ACHIAW]: {
    first: 'Kwasi',
    last: 'Achiaw',
    color: '#D4E13B',
    email: 'kwasi.bvtm@gmail.com',
    phone: '+15159749848',
    schedule_start: '06:00',
    schedule_end: '22:00',
    headshot: 'avatar.jpg',
    location: 'sjc',
    start_address: {
      line1: '4680 Hamilton Avenue',
      city: 'San Jose',
      state: 'CA',
      zip: '95130',
      long: -121.9845007,
      lat: 37.2921493,
    },
    performables: {
      cinematic: true,
      photos: true,
      social: true,
      portraits: true,
      floorplan: true,
      aerial: true,
    },
  },
  [BreProviderId.NOEL_DASMARINAS]: {
    first: 'Noel',
    last: 'Dasmarinas',
    color: '#F8A7DE',
    phone: '+15159749848',
    email: 'noel.dasmarinas0416@gmail.com',
    headshot: 'avatar.jpg',
    location: 'sjc',
    start_address: {
      line1: '4218 Angelina Lane',
      city: 'Stockton',
      state: 'CA',
      zip: '95212',
      long: -121.2648599,
      lat: 38.04748,
    },
    schedule_start: '06:00',
    schedule_end: '22:00',
    performables: {
      cinematic: true,
      floorplan: true,
      aerial: true,
      photos: true,
      social: true,
    },
  },
  [BreProviderId.ALICIA_GARCIA]: {
    first: 'Alicia',
    last: 'Garcia',
    color: '#CFB7E9',
    phone: '+15159749848',
    email: 'alicia.garcia0416@gmail.com',
    schedule_start: '08:00',
    schedule_end: '22:00',
    headshot: 'avatar.jpg',
    location: 'sjc',
    start_address: {
      line1: '35415 Blackburn Drive',
      city: 'Newark',
      state: 'CA',
      zip: '94560',
      long: -122.042221,
      lat: 37.5514634,
    },
    performables: {
      floorplan: true,
      photos: true,
      social: true,
      portraits: true,
    },
  },
  [BreProviderId.DAVID_DINDACK]: {
    first: 'David',
    last: 'Dindack',
    color: '#268C49',
    phone: '+15159749848',
    email: 'davidbeyondremarketing@gmail.com',
    headshot: 'avatar.jpg',
    location: 'sjc',
    start_address: {
      line1: '447 Golf Circle West',
      city: 'Manteca',
      state: 'CA',
      zip: '95337',
      long: -121.2368707,
      lat: 37.8039907,
    },
    schedule_start: '06:00',
    schedule_end: '22:00',
    performables: {
      cinematic: true,
      matterport: true,
      floorplan: true,
      photos: true,
      social: true,
      aerial: true,
    },
  },
  [BreProviderId.IVAN_MARTINEZ]: {
    first: 'Ivan',
    last: 'Martinez',
    color: '#FF0000',
    phone: '+15159749848',
    email: 'ivanbeyondremarketing@gmail.com',
    headshot: 'avatar.jpg',
    location: 'sjc',
    start_address: {
      line1: '9383 Benbow Drive',
      city: 'Gilroy',
      state: 'CA',
      zip: '95020',
      long: -121.5989477,
      lat: 37.0294372,
    },
    schedule_start: '06:00',
    schedule_end: '21:00',
    performables: {
      floorplan: true,
      aerial: true,
      photos: true,
      social: true,
    },
  },
  [BreProviderId.EMILY_MCLAUGHRY]: {
    first: 'Emily',
    last: 'McLaughry',
    color: '#9B9B9B',
    phone: '+15159749848',
    email: 'emilybeyondremarketing@gmail.com',
    headshot: 'avatar.jpg',
    location: 'sjc',
    start_address: {
      line1: '3828 Fenn Way',
      city: 'Santa Cruz',
      state: 'CA',
      zip: '95062',
      long: -121.9703506,
      lat: 36.9819741,
    },
    schedule_start: '06:00',
    schedule_end: '23:00',
    performables: {
      portraits: true,
      floorplan: true,
      aerial: true,
      photos: true,
      cinematic: true,
      social: true,
    },
  },
  [BreProviderId.LAUREN_DINDAK]: {
    first: 'Lauren',
    last: 'Dindak',
    color: '#FF0000',
    email: 'laurenbeyondremarketing@gmail.com',
    phone: '+15159749848',
    headshot: 'avatar.jpg',
    location: 'sjc',
    start_address: {
      line1: '447 Golf Circle West',
      city: 'Manteca',
      state: 'CA',
      zip: '95337',
      long: -121.2368707,
      lat: 37.8039907,
    },
    schedule_start: '04:00',
    schedule_end: '22:00',
    performables: {
      floorplan: true,
      matterport: true,
    },
  },
  [BreProviderId.CHRIS_RICKETTS]: {
    first: 'Chris',
    last: 'Ricketts',
    color: '#A5C5EE',
    email: 'chris@beyondremarketing.com',
    phone: '+15159749848',
    headshot: 'avatar.jpg',
    location: 'sjc',
    start_address: {
      line1: '362 Fantail Way',
      city: 'Gilroy',
      state: 'CA',
      zip: '95020',
      long: -121.5897586,
      lat: 37.0325349,
    },
    schedule_start: '09:00',
    schedule_end: '22:00',
    performables: {
      floorplan: true,
      photos: true,
      social: true,
      aerial: true,
    },
  },
  [BreProviderId.ZACH_CRAWFORD]: {
    first: 'Zach',
    last: 'Crawford',
    color: '#8F2C8F',
    phone: '+15159749848',
    email: 'designzach@gmail.com',
    headshot: 'avatar.jpg',
    location: 'sjc',
    start_address: {
      line1: '15495 Los Gatos Boulevard',
      city: 'Los Gatos',
      state: 'CA',
      zip: '95032',
      long: -121.9607408,
      lat: 37.2442195,
    },
    schedule_start: '04:00',
    schedule_end: '22:00',
    performables: {
      portraits: true,
    },
  },
};

export const BRE_TRAVEL_FEES: Array<{ fee: string; zipcodes: string[] }> = [
  {
    fee: '0',
    zipcodes: [
      '94002',
      '94010',
      '94011',
      '94022',
      '94023',
      '94024',
      '94025',
      '94026',
      '94027',
      '94028',
      '94030',
      '94035',
      '94037',
      '94039',
      '94040',
      '94041',
      '94042',
      '94043',
      '94061',
      '94062',
      '94063',
      '94064',
      '94065',
      '94066',
      '94070',
      '94085',
      '94086',
      '94087',
      '94088',
      '94089',
      '94301',
      '94302',
      '94303',
      '94304',
      '94305',
      '94306',
      '94309',
      '94401',
      '94402',
      '94403',
      '94404',
      '94497',
      '94501',
      '94502',
      '94505',
      '94506',
      '94507',
      '94526',
      '94536',
      '94537',
      '94538',
      '94539',
      '94540',
      '94541',
      '94542',
      '94543',
      '94544',
      '94545',
      '94546',
      '94546',
      '94549',
      '94550',
      '94551',
      '94552',
      '94552',
      '94555',
      '94555',
      '94557',
      '94557',
      '94560',
      '94560',
      '94566',
      '94568',
      '94577',
      '94577',
      '94578',
      '94578',
      '94579',
      '94579',
      '94580',
      '94580',
      '94582',
      '94583',
      '94586',
      '94587',
      '94587',
      '94588',
      '95020',
      '95021',
      '95037',
      '95038',
      '95002',
      '95008',
      '95009',
      '95011',
      '95013',
      '95014',
      '95015',
      '95026',
      '95030',
      '95031',
      '95032',
      '95033',
      '95035',
      '95036',
      '95042',
      '95044',
      '95046',
      '95050',
      '95051',
      '95052',
      '95053',
      '95054',
      '95055',
      '95056',
      '95070',
      '95071',
      '95101',
      '95103',
      '95106',
      '95108',
      '95109',
      '95110',
      '95111',
      '95112',
      '95113',
      '95115',
      '95116',
      '95117',
      '95118',
      '95119',
      '95120',
      '95121',
      '95122',
      '95123',
      '95124',
      '95125',
      '95126',
      '95128',
      '95129',
      '95130',
      '95131',
      '95133',
      '95134',
      '95135',
      '95136',
      '95138',
      '95139',
      '95141',
      '95148',
      '95150',
      '95151',
      '95152',
      '95153',
      '95154',
      '95155',
      '95156',
      '95157',
      '95158',
      '95159',
      '95160',
      '95161',
      '95164',
      '95170',
      '95172',
      '95173',
      '95190',
      '95191',
      '95192',
      '95193',
      '95194',
      '95196',
    ],
  },
  {
    fee: '10',
    zipcodes: [
      '95004',
      '94005',
      '94014',
      '94015',
      '94016',
      '94017',
      '95127',
      '95132',
      '95140',
      '94601',
      '94602',
      '94603',
      '94604',
      '94605',
      '94606',
      '94607',
      '94609',
      '94610',
      '94611',
      '94612',
      '94613',
      '94614',
      '94615',
      '94617',
      '94618',
      '94619',
      '94620',
      '94622',
      '94623',
      '94624',
      '94659',
      '94660',
      '94661',
      '94666',
      '94521',
    ],
  },
  {
    fee: '15',
    zipcodes: [
      '94596',
      '94597',
      '94598',
      '93901',
      '93905',
      '93906',
      '93907',
      '93915',
      '95012',
      '95045',
      '94549',
      '94556',
      '94563',
      '94570',
      '94575',
      '94608',
      '94662',
      '94701',
      '94702',
      '94703',
      '94704',
      '94705',
      '94706',
      '94707',
      '94708',
      '94709',
      '94710',
      '94712',
      '94720',
      '94528',
      '94516',
    ],
  },
  {
    fee: '20',
    zipcodes: [
      '94044',
      '94019',
      '94020',
      '94021',
      '94074',
      '95004',
      '95005',
      '95006',
      '95007',
      '95010',
      '95018',
      '95019',
      '95039',
      '95041',
      '95066',
      '95067',
      '95073',
      '95076',
      '95077',
      '94530',
      '94547',
      '94549',
      '94553',
      '94564',
      '94569',
      '94572',
      '94517',
      '94801',
      '94802',
      '94803',
      '94804',
      '94805',
      '94806',
      '94807',
      '94808',
      '94820',
      '94850',
    ],
  },
  {
    fee: '25',
    zipcodes: [
      '95017',
      '95060',
      '94018',
      '94038',
      '94060',
      '94074',
      '94920',
      '94965',
      '94966',
    ],
  },
  {
    fee: '30',
    zipcodes: [
      '94102',
      '94103',
      '94104',
      '94105',
      '94107',
      '94108',
      '94019',
      '94110',
      '94111',
      '94112',
      '94114',
      '94115',
      '94116',
      '94117',
      '94118',
      '94119',
      '94120',
      '94121',
      '94122',
      '94123',
      '94124',
      '94125',
      '94126',
      '94127',
      '94128',
      '94129',
      '94130',
      '94131',
      '94132',
      '94133',
      '94134',
      '94137',
      '94139',
      '94140',
      '94141',
      '94142',
      '94143',
      '94144',
      '94145',
      '94146',
      '94147',
      '94151',
      '94158',
      '94159',
      '94160',
      '94161',
      '94163',
      '94164',
      '94172',
      '94177',
      '94188',
      '95001',
      '95003',
      '95060',
      '95061',
      '95062',
      '95063',
      '95064',
      '95065',
    ],
  },
  {
    fee: '40',
    zipcodes: [
      '94509',
      '94531',
      '94565',
      '95304',
      '95376',
      '95377',
      '95378',
      '95391',
      '94505',
      '94511',
      '94513',
      '94514',
      '94548',
      '94561',
    ],
  },
  {
    fee: '50',
    zipcodes: [
      '93943',
      '93921',
      '93922',
      '93923',
      '93940',
      '93942',
      '93943',
      '93944',
      '93950',
      '93953',
      '93955',
      '94920',
      '94965',
      '94966',
    ],
  },
];

export const BRE_DELIVERY_FEES: Array<{ upTo: number; rate: string; pay: string }> = [
  { upTo: 25, pay: '0', rate: '0' },
  { upTo: 35, pay: '10', rate: '15' },
  { upTo: 40, pay: '25', rate: '30' },
  { upTo: 45, pay: '35', rate: '40' },
  { upTo: 60, pay: '50', rate: '55' },
  { upTo: 75, pay: '75', rate: '80' },
  { upTo: 150, pay: '175', rate: '170' },
];
