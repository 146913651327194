import dedent from 'dedent';
import z from 'zod';
import { FIRST_VERSION_TIMESTAMP, coerceBoolean, coerceLiteralNumberRequired } from '~/lib/zod';
import { TenantId, createPerformableConfig } from '~/tenants/common/registry';

export default createPerformableConfig(
  'fpv',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
      indoor: coerceBoolean().optional(),
    }),
  },
  {
    name: 'First-Person View Drone Video',
    group: 'Listing',
    tenant: TenantId.BEYOND_RE_MARKETING,
    thumbnail: '/tenant/bre/fpv/thumbnail.jpg',
    images: [],
    short: dedent`
      We now offer the game-changing FPV (First-Person View) drone – a revolutionary \
      tool for real estate marketing. With its advanced features and high-resolution \
      cameras, this cutting-edge technology captures breathtaking aerial footage and \
      provides immersive virtual tours. Showcase unique architectural details and \
      expansive landscapes, captivating potential buyers with stunning bird’s-eye \
      views. Properties marketed with the FPV drone receive an astounding 68% more \
      views, giving you the competitive edge needed in the real estate market. Unlock \
      the true potential of your listings, join industry leaders, and soar above the \
      competition with the affordable yet powerful FPV drone.
    `,
  },
);
