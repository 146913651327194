import { DisplayData, TenantOrderLine } from '~/lib/model';
import { Big } from 'big.js';
import TwtJob from '~/tenants/twt/model/TwtJob';
import { schema_latest_version, ZodVersionedMetadata } from '~/lib/zod';
import TwtAerialConfig, { TwtAerialType } from '~/tenants/twt/performable/aerial/TwtAerialConfig';
import { PersonaType } from '~/tenants/common/TenantPersona';
import { FileData } from '~/lib/schema/order';
import { MicrositeFileType } from '~microsite/lib/types';
import { DeliverableType } from '~common/model/Deliverable';
import { TwtOrderType } from '~/tenants/twt/model/enums';

export const TWT_AERIAL_PIN_PHOTO = 10;
export const TWT_AERIAL_BOUNDARY_PHOTO = 10;
export const TWT_AERIAL_PIN_VIDEO = 15;
export const TWT_AERIAL_BOUNDARY_VIDEO = 15;

export class TwtAerialJob extends TwtJob<typeof TwtAerialConfig> {
  get performable() {
    return TwtAerialConfig;
  }

  get deliverable(): DeliverableType | null {
    if (!this.submission) {
      // aerial and interior video are uploaded as a single video
      return null;
    }

    return DeliverableType.AERIAL;
  }

  get files(): Pick<FileData, 'name' | 'bytes' | 'url' | 'mime'>[] {
    if (!this.metadata.survey) {
      return [];
    }

    return [this.metadata.survey];
  }

  defaultValue(): ZodVersionedMetadata<(typeof TwtAerialConfig)['schema']> {
    // TODO: type fix?
    return {
      version: schema_latest_version(TwtAerialConfig.schema),
      type: TwtAerialType.PHOTO_MINI,
      pin_text: [],
    } as any;
  }

  onsite(): number {
    const onsite = 30;

    if (this.provider) {
      if (this.metadata.type === TwtAerialType.PHOTO_MINI) {
        return this.provider.config.aerial_onsite_mini ?? onsite;
      }

      if (this.metadata.type === TwtAerialType.PHOTO_FULL) {
        return this.provider.config.aerial_onsite_full ?? onsite;
      }

      if (this.metadata.type === TwtAerialType.VIDEO) {
        return this.provider.config.aerial_onsite_video ?? onsite;
      }

      if (this.metadata.type === TwtAerialType.PHOTO_VIDEO) {
        return this.provider.config.aerial_onsite_photo_video ?? onsite;
      }
    }

    return onsite;
  }

  get eligible(): boolean {
    return (
      this.order.metadata.type !== TwtOrderType.SHORT_TERM_RENTAL &&
      this.order.metadata.type !== TwtOrderType.LONG_TERM_RENTAL
    );
  }

  info(): DisplayData[] {
    const info = super.info();

    if (this.metadata.pin_text && this.metadata.pin_text.length > 0) {
      const photos = this.metadata.pin_text.filter((l) => l.type != 'video');
      const videos = this.metadata.pin_text.filter((l) => l.type != 'photo');

      if (photos.length > 0) {
        info.push({
          name: 'Photo Pin/Text',
          value: photos.map((l) => l.location),
          provider: true,
        });

        info.push({
          name: 'Photo Pin/Text',
          value: photos.length.toString(),
          schedule: true,
        });
      }

      if (videos.length > 0) {
        info.push({
          name: 'Video Pin/Text',
          value: videos.map((l) => l.location),
          provider: true,
        });

        info.push({
          name: 'Video Pin/Text',
          value: videos.length.toString(),
          schedule: true,
        });
      }
    }

    if (this.metadata.boundaries_photo && this.metadata.boundaries_photo > 0) {
      info.push({
        name: 'Photo Boundary Lines',
        value: this.metadata.boundaries_photo.toString(),
        provider: true,
        schedule: true,
      });
    }

    if (this.metadata.boundaries_video && this.metadata.boundaries_video > 0) {
      info.push({
        name: 'Video Boundary Lines',
        value: this.metadata.boundaries_video.toString(),
        provider: true,
        schedule: true,
      });
    }

    if (this.metadata.instructions) {
      info.push({
        name: 'Special Instructions',
        value: this.metadata.instructions,
        provider: true,
        schedule: true,
      });
    }

    return info;
  }

  get short() {
    if (this.metadata.type === TwtAerialType.PHOTO_MINI) {
      return 'MINI';
    }

    if (this.metadata.type === TwtAerialType.PHOTO_FULL) {
      return 'FULL';
    }

    if (this.metadata.type === TwtAerialType.VIDEO) {
      return 'VIDEO';
    }

    return 'P + V';
  }

  get name() {
    if (this.metadata.type === TwtAerialType.PHOTO_MINI) {
      return 'Mini Aerial';
    }

    if (this.metadata.type === TwtAerialType.PHOTO_FULL) {
      return 'Full Aerial';
    }

    if (this.metadata.type === TwtAerialType.VIDEO) {
      return 'Aerial Video';
    }

    return 'Aerial Photos + Video';
  }

  get submission() {
    if (this.metadata.type === TwtAerialType.VIDEO && this.order.jobs.some((j) => j.performable_id === 'video')) {
      // aerial and interior video are uploaded as a single video
      return false;
    }

    return true;
  }

  get media() {
    if (this.submission) {
      return { persona: PersonaType.PROVIDER, microsite: MicrositeFileType.GALLERY };
    }

    return null;
  }

  get configurable() {
    return true;
  }

  expenseLines(): TenantOrderLine[] {
    const lines: TenantOrderLine[] = [];

    if (this.metadata.pin_text && this.metadata.pin_text.length > 0) {
      const photos = this.metadata.pin_text.filter((l) => l.type != 'video');
      const videos = this.metadata.pin_text.filter((l) => l.type != 'photo');

      if (photos.length > 0) {
        lines.push({
          description: `Photo Pin/Text Combo (${photos.length})`,
          amount: new Big(TWT_AERIAL_PIN_PHOTO).minus(1).times(this.metadata.pin_text.length),
          id: 'pin_text_photo',
        });
      }

      if (videos.length > 0) {
        lines.push({
          description: `Video Pin/Text Combo (${videos.length})`,
          amount: new Big(TWT_AERIAL_PIN_VIDEO).minus(1).times(this.metadata.pin_text.length),
          id: 'pin_text_video',
        });
      }
    }

    if (this.metadata.boundaries_photo && this.metadata.boundaries_photo > 0) {
      lines.push({
        amount: new Big(TWT_AERIAL_BOUNDARY_PHOTO).minus(1).times(this.metadata.boundaries_photo),
        description: `Photo Boundary Lines (${this.metadata.boundaries_photo})`,
        id: 'boundary_photo',
      });
    }

    if (this.metadata.boundaries_video && this.metadata.boundaries_video > 0) {
      lines.push({
        amount: new Big(TWT_AERIAL_BOUNDARY_VIDEO).minus(1).times(this.metadata.boundaries_video),
        description: `Video Boundary Lines (${this.metadata.boundaries_video})`,
        id: 'boundary_video',
      });
    }

    if (this.metadata.type === TwtAerialType.PHOTO_VIDEO) {
      let amount = new Big(250);

      if (this.order.metadata.type === TwtOrderType.COMMERCIAL) {
        amount = new Big(390);
      }

      lines.push({
        amount,
        description: lines.length === 0 ? 'Aerial Video + Photos' : 'Video + Photos',
        id: 'video_photos',
      });
    } else if (this.metadata.type !== TwtAerialType.VIDEO) {
      let amount = new Big(155);

      if (this.order.metadata.type === TwtOrderType.COMMERCIAL) {
        amount = new Big('162.50');
      } else if (this.metadata.type === TwtAerialType.PHOTO_MINI) {
        amount = new Big(100);
      }

      lines.push({
        amount,
        description: lines.length === 0 ? 'Aerial Photos' : 'Photos',
        id: 'photo',
      });
    } else {
      let amount = new Big(215);

      if (this.order.metadata.type === TwtOrderType.COMMERCIAL) {
        amount = new Big(260);
      }

      lines.push({
        amount,
        description: lines.length === 0 ? 'Aerial Video' : 'Video',
        id: 'video',
      });
    }

    return lines;
  }

  revenueLines(): TenantOrderLine[] {
    const lines: TenantOrderLine[] = [];
    const commercial = this.order.metadata.type === TwtOrderType.COMMERCIAL ? 5 : 0;

    if (this.metadata.pin_text && this.metadata.pin_text.length > 0) {
      const photos = this.metadata.pin_text.filter((l) => l.type != 'video');
      const videos = this.metadata.pin_text.filter((l) => l.type != 'photo');

      if (photos.length > 0) {
        lines.push({
          description: `Photo Pin/Text Combo (${photos.length})`,
          amount: new Big(TWT_AERIAL_PIN_PHOTO).plus(commercial).times(this.metadata.pin_text.length),
          id: 'pin_text_photo',
          taxable: true,
          discountable: true,
        });
      }

      if (videos.length > 0) {
        lines.push({
          description: `Video Pin/Text Combo (${videos.length})`,
          amount: new Big(TWT_AERIAL_PIN_VIDEO).plus(commercial).times(this.metadata.pin_text.length),
          id: 'pin_text_video',
          taxable: true,
          discountable: true,
        });
      }
    }

    if (this.metadata.boundaries_photo && this.metadata.boundaries_photo > 0) {
      lines.push({
        amount: new Big(TWT_AERIAL_BOUNDARY_PHOTO).plus(commercial).times(this.metadata.boundaries_photo),
        description: `Photo Boundary Lines (${this.metadata.boundaries_photo})`,
        id: 'boundary_photo',
        taxable: true,
        discountable: true,
      });
    }

    if (this.metadata.boundaries_video && this.metadata.boundaries_video > 0) {
      lines.push({
        amount: new Big(TWT_AERIAL_BOUNDARY_VIDEO).plus(commercial).times(this.metadata.boundaries_video),
        description: `Video Boundary Lines (${this.metadata.boundaries_video})`,
        id: 'boundary_video',
        taxable: true,
        discountable: true,
      });
    }

    if (this.metadata.type === TwtAerialType.PHOTO_VIDEO) {
      let amount = new Big(325);

      if (this.order.metadata.type === TwtOrderType.COMMERCIAL) {
        amount = new Big(600);
      }

      lines.push({
        amount,
        description: lines.length === 0 ? this.name : 'Video + Photos',
        id: 'video_photos',
        taxable: true,
        discountable: true,
        phase: 'delivered',
      });
    } else if (this.metadata.type !== TwtAerialType.VIDEO) {
      let amount = new Big(200);

      if (this.order.metadata.type === TwtOrderType.COMMERCIAL) {
        amount = new Big(250);
      } else if (this.metadata.type === TwtAerialType.PHOTO_MINI) {
        amount = new Big(135);
      }

      lines.push({
        amount,
        description: lines.length === 0 ? this.name : 'Photos',
        id: 'photo',
        discountable: true,
        taxable: true,
        phase: 'delivered',
      });
    } else {
      let amount = new Big(275);

      if (this.order.metadata.type === TwtOrderType.COMMERCIAL) {
        amount = new Big(400);
      }

      lines.push({
        amount,
        description: lines.length === 0 ? this.name : 'Video',
        id: 'video',
        discountable: true,
        taxable: true,
        phase: 'delivered',
      });
    }

    return lines;
  }
}
