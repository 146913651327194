import FormGroup from '~/components/form/FormGroup';
import { FormHorizontal } from '~/components/form/layout';
import { useTz } from '~/components/hooks/useTz';
import { InputCardOption } from '~/components/input/InputCards';
import ZodFieldDate from '~/components/zod/ZodFieldDate';
import ZodFieldCards from '~/components/zod/ZodFieldCards';
import ZodFieldTextarea from '~/components/zod/ZodFieldTextarea';
import { BreOrderContext, BreOrderType } from '~/tenants/bre/model/BreOrderContext';
import { BreTwilightRate } from '~/tenants/bre/performable/twilight/BreTwilightData';
import { TenantPerformableFormProps } from '~/tenants/common/form';
import { TenantPackageAddOnCheckbox } from '~/tenants/common/package';
import BreListingDisclosure from '~/tenants/bre/performable/BreListingDisclosure';

export default function BreTwilightForm({ context, persona }: TenantPerformableFormProps<BreOrderContext>) {
  const { COPYRIGHT, PHOTOS_15, PHOTOS_25, PHOTOS_8 } = BreTwilightRate[context.metadata.type];
  const tz = useTz();

  const commercialOptions: InputCardOption[] = [
    { title: '15 Photos', cost: PHOTOS_15, value: '15' },
    { title: '25 Photos', cost: PHOTOS_25, value: '25' },
  ];

  const options: InputCardOption[] = [
    { title: '8 Photos', cost: PHOTOS_8, value: '8' },
    ...commercialOptions,
  ];

  return (
    <FormGroup>
      <FormHorizontal name="photos" label="Twilight Package" required>
        <ZodFieldCards
          name="photos"
          options={context.metadata.type === BreOrderType.COMMERCIAL ? commercialOptions : options}
          required
        />
      </FormHorizontal>
      <FormHorizontal
        name="shoot_date"
        label="Shoot Date"
        description="Make sure to set a date after your expected shoot date."
        required
      >
        <ZodFieldDate name="shoot_date" filter={(date) => date >= tz().startOf('day')} />
      </FormHorizontal>
      <FormHorizontal name="notes" label="Notes">
        <ZodFieldTextarea name="notes" />
      </FormHorizontal>
      {context.metadata.type === BreOrderType.COMMERCIAL && (
        <TenantPackageAddOnCheckbox
          name="copyright"
          title="Copyright"
          description="Release photos with copyrights"
          image="" // ? what image should we use here
          persona={persona}
          cost={COPYRIGHT}
          costEach
        />
      )}

      <BreListingDisclosure />
    </FormGroup>
  );
}
