import { format_money } from '~/components/Money';
import InputCheckbox from '~/components/input/InputCheckbox';
import { usePerformableGroup } from '~/components/performable/PerformableGroupForm';
import { useZodFormFieldSingleValue } from '~/components/zod/ZodForm';
import { BreOrderContext, BreOrderType } from '~/tenants/bre/model/BreOrderContext';
import BreMatterportConfig from '~/tenants/bre/performable/matterport/BreMatterportConfig';
import BreMatterportJob from '~/tenants/bre/performable/matterport/BreMatterportJob';
import { PersonaType } from '~/tenants/common/TenantPersona';
import { TenantPackageAddOnTemplate, TenantPackageInclude } from '~/tenants/common/package';

export function BrePackagePhotosInclude() {
  const photos = useZodFormFieldSingleValue('metadata.photos');

  return <TenantPackageInclude name="Ground Photography">{`${photos} Photos`}</TenantPackageInclude>;
}

export function BrePackageFloorplanInclude() {
  return <TenantPackageInclude name="2D Floorplan">Earth-Tone Color 2D Floorplan.</TenantPackageInclude>;
}

export function BrePackageSocialInclude() {
  return <TenantPackageInclude name="Social Media Video">Basic Ground Footage</TenantPackageInclude>;
}

export function BrePackageCinematicInclude() {
  return <TenantPackageInclude name="Cinematic Video">With a free social media edit</TenantPackageInclude>;
}

export function BrePackageBoostInclude() {
  const reach = useZodFormFieldSingleValue('metadata.reach');

  return <TenantPackageInclude name="Social Media Boost">{`Up to ${reach} potential reach`}</TenantPackageInclude>;
}

export function BrePackageWebsiteInclude() {
  return (
    <TenantPackageInclude name="Single Property Website Page">
      1-year subscription to a single property website page
    </TenantPackageInclude>
  );
}

export function BrePackageDuskInclude() {
  return <TenantPackageInclude name="Day-to-Dusk Edit">One complimentary Day-to-Dusk photo edit.</TenantPackageInclude>;
}

export function BrePackageMatterportUpgrade() {
  const { order, addPerformable, removePerformable } = usePerformableGroup<BreOrderContext>();

  if (order.metadata.type === BreOrderType.AGENT_MKTG || order.metadata.type === BreOrderType.PROPERTY_MKTG) {
    return null;
  }

  return (
    <TenantPackageAddOnTemplate
      title="Add-On 3D"
      description="Experience fully immersive inside and out virtual spaces that are so real, it’s like being there"
      image="" // ? what image should we use here
      persona={PersonaType.CUSTOMER}
      cost={format_money(BreMatterportJob.rate(order.metadata.sqft).toFixed(2))}
      enable={
        <InputCheckbox
          onChange={(checked) =>
            checked ? addPerformable(BreMatterportConfig) : removePerformable(BreMatterportConfig)
          }
        />
      }
    />
  );
}
