import Big from 'big.js';
import { match } from 'ts-pattern';
import { TenantJobLine } from '~/lib/model';
import { FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata } from '~/lib/zod';
import BreJob from '~/tenants/bre/model/BreJob';
import BreBusinessCardsConfig, {
  BusinessCardPaper,
} from '~/tenants/bre/performable/print/cards/BreBusinessCardsConfig';
import { BreBusinessCardsPay, BreBusinessCardsRate } from '~/tenants/bre/performable/print/cards/BreBusinessCardsData';
import { DeliverableType } from '~common/model/Deliverable';

export default class BreBusinessCardsJob extends BreJob<typeof BreBusinessCardsConfig> {
  get performable() {
    return BreBusinessCardsConfig;
  }

  get configurable(): boolean {
    return true;
  }

  get deliverable(): DeliverableType {
    return DeliverableType.BROCHURE;
  }

  get paperType(): string {
    const { paper } = this.metadata;
    return BreBusinessCardsJob.paperType(paper);
  }

  static paperType(paper: BusinessCardPaper): string {
    return match(paper)
      .with('uv_glossy', () => 'UV glossy coated')
      .with('satin_matte', () => 'Satin Matte coated ')
      .exhaustive();
  }

  isDelivery(): boolean {
    return true;
  }

  isPrint(): boolean {
    return true;
  }

  defaultValue(): ZodVersionedMetadata<(typeof BreBusinessCardsConfig)['schema']> {
    return {
      version: FIRST_VERSION_TIMESTAMP,
      count: '50',
      paper: 'uv_glossy',
      need_by: '' as TDateISODate,
      delivery: {
        city: '',
        distance: null,
        lat: 0,
        line1: '',
        long: 0,
        state: '',
        zip: '',
      },
    };
  }

  revenueLines(): TenantJobLine[] {
    const { count, paper } = this.metadata;
    const rate = BreBusinessCardsRate[paper][count];
    const lines: TenantJobLine[] = [];

    if (rate) {
      lines.push({
        id: 'business_cards_print',
        description: `${this.paperType} business cards (x${count})`,
        amount: new Big(rate),
      });
    }

    return lines;
  }

  expenseLines(): TenantJobLine[] {
    const { count, paper } = this.metadata;
    const pay = BreBusinessCardsPay[paper][count];
    const lines: TenantJobLine[] = [];

    if (pay) {
      lines.push({
        id: 'business_cards_print',
        description: `${this.paperType} business cards (x${count})`,
        amount: new Big(pay),
      });
    }

    return lines;
  }
}
