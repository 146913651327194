import OrderWizardTypeCard from '~/tenants/common/forms/OrderWizardTypeCard';
import { MpiOrderType } from '~/tenants/mpi/model/MpiOrder';

// TODO: Joel: Need to configure images for this wizard form
export default function MpiOrderWizardType() {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
      <OrderWizardTypeCard value={MpiOrderType.RESIDENTIAL} image="tenant/twt/residential/1.jpg" title="Residential">
        Residential home description (TBC)
      </OrderWizardTypeCard>
    </div>
  );
}
