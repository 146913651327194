import z from 'zod';
import * as v from 'valibot';
import { createPerformableConfig, TenantId } from '~/tenants/common/registry';
import { coerceBoolean, coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP } from '~/lib/zod';
import { boostSchema } from '~/tenants/bre/performable/boost/BreBoostConfig';
import { voiceSchema } from '~/tenants/bre/performable/common';
import dedent from 'dedent';

export default createPerformableConfig(
  'social',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
      aerial: coerceBoolean().optional(),
      intro: coerceBoolean().optional(),
      narration: coerceBoolean().optional(),
      kwasi: voiceSchema.optional(),
      boost: boostSchema.optional(),
      rush: coerceBoolean().optional(),
      self_edited: coerceBoolean().optional(),
    }),
  },
  {
    name: 'Social Media Video',
    group: 'Listing',
    tenant: TenantId.BEYOND_RE_MARKETING,
    images: [],
    short: dedent`
      We create high-quality social media videos using top-tier stabilized \
      gimbals for smooth, professional footage. Our expert editing team ensures \
      a polished final product. Enhance your videos with optional agent intros \
      and voice narration for added impact.
    `,
    bullets: ['Includes 5 aerial photos'],
    submit: v.object({
      self_edited: v.boolean(),
    }),
  },
);
