import z from 'zod';
import { createPerformableConfig, TenantId } from '~/tenants/common/registry';
import {
  coerceBoolean,
  coerceLiteralNumberRequired,
  coerceOptionalPositiveNumber,
  FIRST_VERSION_TIMESTAMP,
} from '~/lib/zod';
import * as v from 'valibot';
import { voiceSchema } from '~/tenants/bre/performable/common';
import dedent from 'dedent';

export default createPerformableConfig(
  'cinematic',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
      aerial_upgrade: coerceBoolean().optional(),
      intro: coerceBoolean().optional(),
      narration: coerceBoolean().optional(),
      kwasi: voiceSchema.optional(),
      twilight: coerceBoolean().optional(),
      aerial_photos: coerceBoolean().optional(),
      duration: coerceOptionalPositiveNumber(),
      rush: coerceBoolean().optional(),
      self_edited: coerceBoolean().optional(),
    }),
  },
  {
    name: 'Cinematic Video',
    group: 'Listing',
    tenant: TenantId.BEYOND_RE_MARKETING,
    thumbnail: '/tenant/bre/cinematic/thumbnail.jpg',
    images: [],
    short: dedent`
      Cinematic Style Films will engage potential buyers and bring you new \
      clients in the future! Our new Cinematic Style Films are not just your \
      run-of-the-mill walk-throughs videos anymore!
    `,
    bullets: ['Includes a free up to 90 seconds social media edit.'],
    submit: v.object({
      self_edited: v.boolean(),
    }),
  },
);
