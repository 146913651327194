import { createPerformableConfig, TenantId } from '~/tenants/common/registry';
import { coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP } from '~/lib/zod';
import z from 'zod';

export default createPerformableConfig(
  'floorplan',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
    }),
  },
  {
    name: '2D Floor Plan',
    // TODO: Joel: Revisit this
    thumbnail: 'tenant/twt/floorplan/standard2.png',
    short:
      "Our 2D Floor Plan service provides an excellent tool for helping potential buyers to visualize a home's unique layout.",
    tenant: TenantId.MPI,
    images: [],
  },
);
