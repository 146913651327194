import classNames from 'classnames';
import { useEffect, useId } from 'react';
import { format_money } from '~/components/Money';

export interface InputRadioCardOption {
  title: string;
  description?: string;
  cost?: string | number;
  disabled?: boolean;
  value: string;
}

export interface InputRadioCardsProps {
  cols?: number;
  required?: boolean;
  disabled?: boolean;
  name?: string;
  onBlur?: () => void;
  onChange?: (value: string | null) => void;
  onFocus?: () => void;
  options: InputRadioCardOption[];
  value?: string | null;
}

export default function InputRadioCards(props: InputRadioCardsProps) {
  const { cols = 3, required, disabled, name, onBlur, onChange, onFocus, options, value } = props;

  const optionsId = useId();

  useEffect(() => {
    if (value !== undefined && value !== null) {
      const selected = options.find((option) => option.value === value);

      if (selected?.disabled) {
        const next = options.find((option) => !option.disabled);
        onChange?.(next?.value ?? null);
      }
    }
  }, [value, JSON.stringify(options), onChange]);

  useEffect(() => {
    if (required) {
      const hasOptions = !options.every((option) => option.disabled);
      const hasValue = value !== undefined && value !== null;

      if (hasOptions && !hasValue) {
        const next = options.find((option) => !option.disabled);
        onChange?.(next?.value ?? null);
      }
    }
  }, [required, value, JSON.stringify(options), onChange]);

  return (
    <div className={`grid grid-cols-1 md:grid-cols-${cols} gap-3`}>
      {options.map((option) => {
        const selected = value === option.value;
        const isDisabled = disabled || option.disabled;

        return (
          <button
            key={`${optionsId}-${option.value}`}
            className={classNames(
              'flex flex-col p-3 text-left border border-gray-300 rounded-theme outline-none transition ease-in-out duration-200',
              {
                'hover:border-gray-400': !isDisabled && !selected,
                'border-gray-900 ring-1 ring-black': selected,
                'bg-gray-100 cursor-not-allowed': isDisabled,
              },
            )}
            onClick={() => onChange?.(selected && !required ? null : option.value)}
            disabled={isDisabled}
            onFocus={onFocus}
            onBlur={onBlur}
            type="button"
          >
            <span className={classNames('font-bold text-gray-400', { 'text-gray-800': !isDisabled })}>
              {option.title}
            </span>
            {(option.description || option.cost) && (
              <div className={classNames('text-sm text-gray-400', { 'text-gray-600': !isDisabled })}>
                {option.description && <div>{option.description}</div>}
                {option.cost && <div>for {format_money(option.cost)}</div>}
              </div>
            )}
          </button>
        );
      })}

      {name && value && <input name={name} type="hidden" value={value} />}
    </div>
  );
}
