import { DisplayData, TenantOrderLine } from '~/lib/model';
import { Big } from 'big.js';
import TwtJob from '~/tenants/twt/model/TwtJob';
import { schema_latest_version, ZodVersionedMetadata } from '~/lib/zod';
import { PersonaType } from '~/tenants/common/TenantPersona';
import TwtVideoConfig from '~/tenants/twt/performable/video/TwtVideoConfig';
import { MicrositeFileType } from '~microsite/lib/types';
import { DeliverableType } from '~common/model/Deliverable';
import { TwtOrderType } from '~/tenants/twt/model/enums';

export const TWT_VIDEO_VOICEOVER = 100;

export class TwtVideoJob extends TwtJob<typeof TwtVideoConfig> {
  get performable() {
    return TwtVideoConfig;
  }

  get submission() {
    return true;
  }

  get deliverable(): DeliverableType {
    return DeliverableType.VIDEO;
  }

  info(): DisplayData[] {
    const info = super.info();

    if (this.metadata.voiceover) {
      info.push({
        name: 'Voiceover',
        value: 'Yes',
        provider: true,
        schedule: true,
      });
    }

    return info;
  }

  defaultValue(): ZodVersionedMetadata<(typeof TwtVideoConfig)['schema']> {
    // TODO: type fix?
    return {
      version: schema_latest_version(TwtVideoConfig.schema),
      social: false,
    } as any;
  }

  get configurable() {
    return true;
  }

  get short() {
    return 'VIDEO';
  }

  onsite(): number {
    let onsite = 30;

    if (this.provider) {
      if (this.sqft <= 2000) {
        onsite = this.provider.config.video_onsite_0_2000 ?? onsite;
      } else if (this.sqft <= 3000) {
        onsite = this.provider.config.video_onsite_2001_3000 ?? onsite;
      } else {
        onsite = this.provider.config.video_onsite_over_3000 ?? onsite;
      }

      if (this.provider.config.cameo_onsite && this.metadata.voiceover) {
        onsite += this.provider.config.cameo_onsite;
      }
    }

    return onsite;
  }

  get media() {
    return { persona: PersonaType.PROVIDER, microsite: MicrositeFileType.VIDEO };
  }

  expenseLines(): TenantOrderLine[] {
    const lines = [
      {
        amount: this.orderHasAerialVideo ? (this.orderHasAerialPhoto ? new Big(190) : new Big(185)) : new Big(300),
        description: this.performable.name,
        id: 'interior',
      },
    ];

    if (this.metadata.voiceover) {
      lines.push({
        amount: new Big(80),
        description: 'Agent Cameo / Voiceover',
        id: 'voiceover',
      });
    }

    return lines;
  }

  revenueLines(): TenantOrderLine[] {
    const lines = super.revenueLines();

    if (this.order.metadata.type === TwtOrderType.COMMERCIAL) {
      lines.push({
        amount: this.orderHasAerialVideo ? new Big(325) : new Big(500),
        description: this.performable.name,
        id: 'interior',
        taxable: true,
        discountable: true,
      });
    } else {
      lines.push({
        amount: this.orderHasAerialVideo ? new Big(225) : new Big(400),
        description: this.performable.name,
        id: 'interior',
        taxable: true,
        discountable: true,
      });
    }

    if (this.metadata.raw) {
      lines.push({
        amount: new Big(75),
        description: 'Raw Video Footage',
        discountable: true,
        taxable: true,
        id: 'raw',
      });
    }

    if (this.metadata.voiceover) {
      lines.push({
        amount: new Big(100),
        taxable: true,
        description: 'Agent Cameo / Voiceover',
        id: 'voiceover',
        discountable: true,
      });
    }

    return lines;
  }
}
