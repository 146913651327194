import Big from 'big.js';
import { TenantJobLine } from '~/lib/model';
import { FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata } from '~/lib/zod';
import BreJob from '~/tenants/bre/model/BreJob';
import BreFpvVideoConfig from '~/tenants/bre/performable/fvp/BreFpvVideoConfig';
import { BreFpvVideoPay, BreFpvVideoRate } from '~/tenants/bre/performable/fvp/BreFpvVideoData';
import { PersonaType } from '~/tenants/common/TenantPersona';
import { DeliverableType } from '~common/model/Deliverable';
import { MicrositeFileType } from '~microsite/lib/types';

export default class BreFpvVideoJob extends BreJob<typeof BreFpvVideoConfig> {
  get performable() {
    return BreFpvVideoConfig;
  }

  get configurable() {
    return true;
  }

  get deliverable() {
    return DeliverableType.AERIAL;
  }

  get media() {
    return {
      persona: PersonaType.PROVIDER,
      microsite: MicrositeFileType.GALLERY,
    };
  }

  get eligible(): boolean {
    return !this.isMarketing;
  }

  defaultValue(): ZodVersionedMetadata<(typeof BreFpvVideoConfig)['schema']> {
    return {
      version: FIRST_VERSION_TIMESTAMP,
    };
  }

  onsite(): number {
    const { indoor } = this.metadata;
    return indoor ? 60 : 30;
  }

  revenueLines(): TenantJobLine[] {
    const { indoor } = this.metadata;
    const { BASE, INDOOR } = BreFpvVideoRate[this.order.metadata.type];
    const lines: TenantJobLine[] = [];

    lines.push({
      id: 'outdoor',
      description: 'Outdoor FPV video',
      amount: new Big(BASE),
    });

    if (indoor) {
      lines.push({
        id: 'indoor',
        description: 'Indoor FPV video',
        amount: new Big(INDOOR),
      });
    }

    return lines;
  }

  expenseLines(): TenantJobLine[] {
    const { indoor } = this.metadata;
    const { BASE, INDOOR } = BreFpvVideoPay[this.order.metadata.type];
    const lines: TenantJobLine[] = [];

    lines.push({
      id: 'outdoor',
      description: 'Outdoor FPV video',
      amount: new Big(BASE).times('0.7'),
    });

    if (indoor) {
      lines.push({
        id: 'indoor',
        description: 'Indoor FPV video',
        amount: new Big(INDOOR).times('0.7'),
      });
    }

    return lines;
  }
}
