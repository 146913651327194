import FormGroup from '~/components/form/FormGroup';
import { FormHorizontal } from '~/components/form/layout';
import { useTz } from '~/components/hooks/useTz';
import { InputCardOption } from '~/components/input/InputCards';
import ZodFieldDate from '~/components/zod/ZodFieldDate';
import ZodFieldCards from '~/components/zod/ZodFieldCards';
import { isBusinessDay, plusBusinessDays } from '~/lib/datettime';
import { typeSchema } from '~/tenants/bre/performable/print/guides/BreGuidesConfig';
import BreGuidesJob from '~/tenants/bre/performable/print/guides/BreGuidesJob';

const TYPE_OPTS = typeSchema.options.map<InputCardOption>((value) => ({
  title: BreGuidesJob.typeText(value),
  description: 'guide',
  value,
}));

export default function BreGuidesForm() {
  const tz = useTz();

  return (
    <FormGroup>
      <FormHorizontal name="types" label="Guides" description="Select all the guides you need." required>
        <ZodFieldCards name="types" options={TYPE_OPTS} multiple />
      </FormHorizontal>
      <FormHorizontal name="need_by" label="Need By" required>
        <ZodFieldDate
          name="need_by"
          filter={(date) => isBusinessDay(date) && date >= plusBusinessDays(tz().startOf('day'), 2)}
        />
      </FormHorizontal>
    </FormGroup>
  );
}
