import Big from 'big.js';
import { match } from 'ts-pattern';
import { datetime } from '~/lib/datettime';
import { TimeZone } from '~/lib/enum';
import { TenantJobLine } from '~/lib/model';
import { FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata } from '~/lib/zod';
import BreJob from '~/tenants/bre/model/BreJob';
import {
  CONTENT_WRITING_PAY,
  CONTENT_WRITING_RATE,
  NeedBy,
  deliveryFee,
  toNeedBy,
} from '~/tenants/bre/performable/common';
import BreBrochuresConfig, {
  BrochurePaper,
  BrochureSide,
} from '~/tenants/bre/performable/print/brochures/BreBrochuresConfig';
import { BreBrochuresPay, BreBrochuresRate } from '~/tenants/bre/performable/print/brochures/BreBrochuresData';
import { BreDesignPay, BreDesignRate } from '~/tenants/bre/performable/print/design/BreDesignData';
import { DeliverableType } from '~common/model/Deliverable';

interface PaperTypeOptions {
  paper: BrochurePaper;
  sides: BrochureSide;
  no_free_uv?: boolean;
}

export default class BreBrochuresJob extends BreJob<typeof BreBrochuresConfig> {
  get performable() {
    return BreBrochuresConfig;
  }

  get configurable(): boolean {
    return true;
  }

  get deliverable(): DeliverableType {
    return DeliverableType.BROCHURE;
  }

  get paperType(): string {
    const { paper, sides, no_free_uv } = this.metadata;
    return BreBrochuresJob.paperType({ paper, sides, no_free_uv });
  }

  get needBy(): NeedBy {
    return toNeedBy(this.metadata.need_by);
  }

  static paperType({ paper, sides, no_free_uv }: PaperTypeOptions): string {
    const withUv = (sides === '8' && paper === 'heavy_low_gloss_uv') || no_free_uv ? 'with UV' : 'with free UV';

    return match(paper)
      .with('heavy_low_gloss', () => 'Heavy-gloss outside, low-gloss inside')
      .with('heavy_low_gloss_uv', () => `Heavy-gloss ${withUv} outside, low-gloss inside`)
      .with('heavy_heavy_gloss_uv', () => `Heavy-gloss ${withUv} outside, heavy-gloss inside`)
      .with('super_heavy_low_gloss_uv', () => `Super heavy-gloss ${withUv} outside, low-gloss inside`)
      .with('super_heavy_heavy_gloss_uv', () => `Super heavy-gloss ${withUv} outside, heavy-gloss inside`)
      .exhaustive();
  }

  isDelivery(): boolean {
    return true;
  }

  isPrint(): boolean {
    return true;
  }

  defaultValue(): ZodVersionedMetadata<(typeof BreBrochuresConfig)['schema']> {
    return {
      version: FIRST_VERSION_TIMESTAMP,
      sides: '8',
      count: '50',
      paper: 'heavy_low_gloss',
      need_by: '' as TDateISODate,
      delivery: {
        city: '',
        distance: null,
        lat: 0,
        line1: '',
        long: 0,
        state: '',
        zip: '',
      },
    };
  }

  revenueLines(): TenantJobLine[] {
    const { count, paper, sides, delivery, content_writing, design } = this.metadata;
    const rate = BreBrochuresRate[this.needBy][sides]?.[paper]?.[count];
    const lines: TenantJobLine[] = [];

    if (rate) {
      lines.push({
        id: 'brochures_print',
        description: `${sides} Pages ${this.paperType} (x${count})`,
        amount: new Big(rate),
      });
    }

    if (delivery.time !== undefined) {
      const rate = deliveryFee(delivery.time, 'rate');

      if (rate) {
        lines.push({
          id: 'delivery_fee',
          description: `Delivery Fee (${delivery.city})`,
          amount: new Big(rate),
        });
      }
    }

    if (content_writing) {
      lines.push({
        id: 'content_writing',
        description: 'Content Writing',
        amount: new Big(CONTENT_WRITING_RATE),
      });
    }

    if (design) {
      const rate = BreDesignRate[design][`brochure_${sides}`];

      if (rate) {
        lines.push({
          id: 'brochure_design',
          description: `Brochure design (${design} layout)`,
          amount: new Big(rate),
        });
      }
    }

    return lines;
  }

  expenseLines(): TenantJobLine[] {
    const { count, paper, sides, delivery, content_writing, design } = this.metadata;
    const pay = BreBrochuresPay[this.needBy][sides]?.[paper]?.[count];
    const lines: TenantJobLine[] = [];

    if (pay) {
      lines.push({
        id: 'brochures_print',
        description: `${sides} Pages ${this.paperType} (x${count})`,
        amount: new Big(pay),
      });
    }

    if (delivery.time !== undefined) {
      const pay = deliveryFee(delivery.time, 'pay');

      if (pay) {
        lines.push({
          id: 'delivery_fee',
          description: `Delivery Fee (${delivery.city})`,
          amount: new Big(pay),
        });
      }
    }

    if (content_writing) {
      lines.push({
        id: 'content_writing',
        description: 'Content Writing',
        amount: new Big(CONTENT_WRITING_PAY),
      });
    }

    if (design) {
      const pay = BreDesignPay[design][`brochure_${sides}`];

      if (pay) {
        lines.push({
          id: 'brochure_design',
          description: `Brochure design (${design} layout)`,
          amount: new Big(pay),
        });
      }
    }

    return lines;
  }
}
