import z from 'zod';
import { FIRST_VERSION_TIMESTAMP, coerceLiteralNumberRequired, coerceOptionalPositiveNumber } from '~/lib/zod';
import { TenantId, createPerformableConfig } from '~/tenants/common/registry';

export default createPerformableConfig(
  'custom',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
      description: z.string(),
      rate: coerceOptionalPositiveNumber(),
      pay: coerceOptionalPositiveNumber(),
      onsite: z.coerce.number().optional(),
    }),
  },
  {
    name: 'Custom Service',
    images: [],
    tenant: TenantId.BEYOND_RE_MARKETING,
    short: '',
  },
);
