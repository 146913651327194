import { BreOrderType } from '~/tenants/bre/model/BreOrderContext';

const BaseRates = {
  DOMAIN: '25',
  RENEWAL: '20',
  WEBSITE: '50',
};

export const BreWebsiteRate: Record<BreOrderType, typeof BaseRates> = {
  [BreOrderType.COMMERCIAL]: BaseRates,
  [BreOrderType.RENTAL]: BaseRates,
  [BreOrderType.RESIDENTIAL]: BaseRates,
  [BreOrderType.PROPERTY_MKTG]: BaseRates,
  [BreOrderType.AGENT_MKTG]: BaseRates,
};
