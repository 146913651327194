import { Reach } from '~/tenants/bre/performable/boost/BreBoostConfig';

type ReachMap = Record<Reach, string>;

export const BreBoostRate: ReachMap = {
  '3,000': '200',
  '5,000': '300',
  '7,000': '400',
  '9,250': '500',
  '11,500': '600',
  '14,000': '700',
  '16,500': '800',
  '20,000': '900',
  '24,000': '1000',
};
