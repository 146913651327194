import Big from 'big.js';
import { TenantJobLine } from '~/lib/model';
import { FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata } from '~/lib/zod';
import BreJob from '~/tenants/bre/model/BreJob';
import { BreDesignPay, BreDesignRate } from '~/tenants/bre/performable/print/design/BreDesignData';
import BrePostcardsPrintOnlyConfig from '~/tenants/bre/performable/print/postcards/printonly/BrePostcardsPrintOnlyConfig';
import {
  BrePostcardsPrintOnlyPay,
  BrePostcardsPrintOnlyRate,
} from '~/tenants/bre/performable/print/postcards/printonly/BrePostcardsPrintOnlyData';
import { DeliverableType } from '~common/model/Deliverable';

export default class BrePostcardsPrintOnlyJob extends BreJob<typeof BrePostcardsPrintOnlyConfig> {
  get performable() {
    return BrePostcardsPrintOnlyConfig;
  }

  get configurable(): boolean {
    return true;
  }

  get deliverable(): DeliverableType {
    return DeliverableType.BROCHURE;
  }

  isDelivery(): boolean {
    return true;
  }

  isPrint(): boolean {
    return true;
  }

  defaultValue(): ZodVersionedMetadata<(typeof BrePostcardsPrintOnlyConfig)['schema']> {
    return {
      version: FIRST_VERSION_TIMESTAMP,
      count: '50',
      need_by: '' as TDateISODate,
    };
  }

  revenueLines(): TenantJobLine[] {
    const { count, design } = this.metadata;
    const rate = BrePostcardsPrintOnlyRate[count];
    const lines: TenantJobLine[] = [];

    if (rate) {
      lines.push({
        id: 'postcards_printonly',
        description: `Print-only Post Cards (x${count})`,
        amount: new Big(rate),
      });
    }

    if (design) {
      const designRate = BreDesignRate[design].postcard_half;

      if (designRate) {
        lines.push({
          id: 'porcard_printonly_design',
          description: `Postcard design (${design} layout)`,
          amount: new Big(designRate),
        });
      }
    }

    return lines;
  }

  expenseLines(): TenantJobLine[] {
    const { count, design } = this.metadata;
    const pay = BrePostcardsPrintOnlyPay[count];
    const lines: TenantJobLine[] = [];

    if (pay) {
      lines.push({
        id: 'postcards_printonly',
        description: `Print-only Post Cards (x${count})`,
        amount: new Big(pay),
      });
    }

    if (design) {
      const designPay = BreDesignPay[design].postcard_half;

      if (designPay) {
        lines.push({
          id: 'porcard_printonly_design',
          description: `Postcard design (${design} layout)`,
          amount: new Big(designPay),
        });
      }
    }

    return lines;
  }
}
