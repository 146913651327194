import { TenantPackageConfig } from '~/tenants/common/registry';
import TwtPhotoConfig from '~/tenants/twt/performable/photo/TwtPhotoConfig';
import TwtFloorplanConfig from '~/tenants/twt/performable/floorplan/TwtFloorplanConfig';
import { TwtOrderContext } from '~/tenants/twt/model/TwtOrder';
import { TwtOrderType } from '~/tenants/twt/model/enums';

const TwtRentalFloorplanConfig: TenantPackageConfig = {
  id: 'rental_floorplan',
  name: 'Photos + Floor Plan',
  description:
    'Capture your property with HDR photography and a stylized floor plan organized into a customized microsite.',
  performables: [TwtPhotoConfig, TwtFloorplanConfig],
  eligible: (context) =>
    (context as TwtOrderContext).metadata.type === TwtOrderType.LONG_TERM_RENTAL ||
    (context as TwtOrderContext).metadata.type === TwtOrderType.SHORT_TERM_RENTAL,
};

export default TwtRentalFloorplanConfig;
