import z from 'zod';
import { FIRST_VERSION_TIMESTAMP, coerceBoolean, coerceLiteralNumberRequired, zodTDateISODate } from '~/lib/zod';
import { deliverySchema } from '~/tenants/bre/performable/common';
import { TenantId, createPerformableConfig } from '~/tenants/common/registry';

export type RiderShipping = z.infer<typeof shippingSchema>;

export const holesSchema = z.enum(['2', '4']);

export const shippingSchema = z.enum(['courier', 'ups']);

export default createPerformableConfig(
  'riders',
  {
    [FIRST_VERSION_TIMESTAMP]: z
      .object({
        version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
        holes: holesSchema,
        shipping: shippingSchema,
        need_by: zodTDateISODate(),
        delivery: deliverySchema.optional(),
        no_qr_code: coerceBoolean().optional(),
      })
      .superRefine(({ delivery, shipping }, ctx) => {
        if (shipping === 'courier' && delivery === undefined) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'delivery is required when shipping is by courier',
            path: ['delivery'],
          });
        }
      }),
  },
  {
    name: 'Sign Riders',
    group: 'Property Marketing',
    tenant: TenantId.BEYOND_RE_MARKETING,
    thumbnail: '/tenant/bre/print/riders/thumbnail.jpg',
    images: [],
    short: "24'' x 6'' Yard Sign Riders available with 2 or 4 holes on durable 3mm PVC with a custom URL required.",
  },
);
