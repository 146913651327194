import Big from 'big.js';
import { TenantOrderLine } from '~/lib/model';
import BreMatterportConfig from '~/tenants/bre/performable/matterport/BreMatterportConfig';
import BreJob from '~/tenants/bre/model/BreJob';
import { BreOrderType } from '~/tenants/bre/model/BreOrderContext';
import { DeliverableType } from '~common/model/Deliverable';
import { FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata } from '~/lib/zod';
import { BreMatterportRate } from '~/tenants/bre/performable/matterport/BreMatterportData';
import BreFloorplanJob from '~/tenants/bre/performable/floorplan/BreFloorplanJob';
import { PersonaType } from '~/tenants/common/TenantPersona';
import { MicrositeFileType } from '~microsite/lib/types';
import { match } from 'ts-pattern';

export default class BreMatterportJob extends BreJob<typeof BreMatterportConfig> {
  get performable() {
    return BreMatterportConfig;
  }

  get configurable(): boolean {
    return true;
  }

  get deliverable(): DeliverableType {
    return DeliverableType.MATTERPORT;
  }

  get submission(): boolean {
    return true;
  }

  get sqft(): number {
    return this.metadata.sqft ?? super.sqft;
  }

  get media() {
    return {
      persona: PersonaType.PROVIDER,
      microsite: MicrositeFileType.FLOORPLAN,
    };
  }

  get eligible(): boolean {
    return !this.isMarketing;
  }

  static rate(sqft: number): Big {
    return new Big('0.06').times(sqft).plus(220.5);
  }

  defaultValue(): ZodVersionedMetadata<(typeof BreMatterportConfig)['schema']> {
    return {
      version: FIRST_VERSION_TIMESTAMP,
    };
  }

  onsite(): number {
    const { outdoor_back, outdoor_front, outdoor_commercial } = this.metadata;
    const sqftTime = match(this.sqft)
      .when(
        (sqft) => sqft <= 0,
        () => 0,
      )
      .when(
        (sqft) => sqft <= 1500,
        () => 45,
      )
      .when(
        (sqft) => sqft <= 2500,
        () => 60,
      )
      .when(
        (sqft) => sqft <= 3500,
        () => 75,
      )
      .otherwise(() => 90);
    const frontTime = outdoor_front ? 20 : 0;
    const backTime = outdoor_back ? 20 : 0;
    const outdoorsTime = outdoor_commercial ? 30 : 0;

    return sqftTime + frontTime + backTime + outdoorsTime;
  }

  revenueLines(): TenantOrderLine[] {
    const { type } = this.order.metadata;
    const { outdoor_back, outdoor_commercial, outdoor_front, plan, floorplan } = this.metadata;
    const { OUTDOOR, OUTDOOR_XL } = BreMatterportRate[type];
    const lines: TenantOrderLine[] = [];

    if (type !== BreOrderType.COMMERCIAL) {
      if (this.sqft > 0) {
        lines.push({
          id: '3d_matterport',
          description: '3D Scan',
          amount: BreMatterportJob.rate(this.sqft),
        });

        if (outdoor_front) {
          lines.push({
            id: 'outdoor_front',
            description: `Front Yard`,
            amount: new Big(this.sqft < 8000 ? OUTDOOR : OUTDOOR_XL),
          });
        }

        if (outdoor_back) {
          lines.push({
            id: 'outdoor_back',
            description: `Back Yard`,
            amount: new Big(this.sqft < 8000 ? OUTDOOR : OUTDOOR_XL),
          });
        }
      }
    }

    if (type === BreOrderType.COMMERCIAL) {
      if (this.sqft > 0) {
        lines.push({
          id: '3d_matterport',
          description: `3D scan (${plan})`,
          amount: new Big(plan === 'basic' ? '0.07' : '0.09').times(this.sqft),
        });

        if (outdoor_commercial) {
          lines.push({
            id: 'outdoor_commercial',
            description: `Outdoor Matterport (${plan})`,
            amount: new Big(plan === 'basic' ? OUTDOOR : OUTDOOR_XL),
          });
        }
      }
    }

    if (floorplan) {
      lines.push({
        id: '2d_floorplan',
        description: '2D Floorplan',
        amount: BreFloorplanJob.rate(this.sqft, type),
      });
    }

    return lines;
  }

  expenseLines(): TenantOrderLine[] {
    const { type } = this.order.metadata;
    const { outdoor_back, outdoor_commercial, outdoor_front, plan, floorplan } = this.metadata;
    const lines: TenantOrderLine[] = [];

    if (type !== BreOrderType.COMMERCIAL) {
      if (this.sqft > 0) {
        lines.push({
          id: '3d_matterport',
          description: '3D Scan',
          amount: new Big('0.04').times(this.sqft).plus('135'),
        });

        if (outdoor_front) {
          const charge = outdoor_back ? '150' : '125';

          lines.push({
            id: 'outdoor_front',
            description: `Front Yard`,
            amount: new Big(this.sqft < 8000 ? charge : '300'),
          });
        }

        if (outdoor_back) {
          const charge = outdoor_front ? '150' : '125';

          lines.push({
            id: 'outdoor_back',
            description: `Back Yard`,
            amount: new Big(this.sqft < 8000 ? charge : '300'),
          });
        }
      }
    }

    if (type === BreOrderType.COMMERCIAL) {
      if (this.sqft > 0) {
        lines.push({
          id: '3d_matterport',
          description: `3D Scan (${plan})`,
          amount: new Big(plan === 'basic' ? '0.05' : '0.07').times(this.sqft),
        });

        if (outdoor_commercial) {
          lines.push({
            id: 'outdoor_commercial',
            description: `Outdoors Scan (${plan})`,
            amount: new Big(plan === 'basic' ? '600' : '1000'),
          });
        }
      }
    }

    if (floorplan) {
      lines.push({
        id: '2d_floorplan',
        description: '2D Floorplan',
        amount: BreFloorplanJob.pay(this.sqft, type),
      });
    }

    return lines;
  }
}
