import Big from 'big.js';
import { match } from 'ts-pattern';
import { TenantJobLine } from '~/lib/model';
import { FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata } from '~/lib/zod';
import BreJob from '~/tenants/bre/model/BreJob';
import BreGuidesConfig, { GuideType } from '~/tenants/bre/performable/print/guides/BreGuidesConfig';
import { BreGuidesRate } from '~/tenants/bre/performable/print/guides/BreGuidesData';

export default class BreGuidesJob extends BreJob<typeof BreGuidesConfig> {
  get performable() {
    return BreGuidesConfig;
  }

  get configurable(): boolean {
    return true;
  }

  static typeText(type: GuideType): string {
    return match(type)
      .with('selling', () => 'Selling')
      .with('buyers', () => 'Buyers')
      .with('after_sale', () => 'After the Sale')
      .with('preparing_for_sale', () => 'Preparing Your Home for Sale')
      .with('contract_to_close', () => 'Contract to Close')
      .exhaustive();
  }

  defaultValue(): ZodVersionedMetadata<(typeof BreGuidesConfig)['schema']> {
    return {
      version: FIRST_VERSION_TIMESTAMP,
      types: ['selling'],
      need_by: '' as TDateISODate,
    };
  }

  revenueLines(): TenantJobLine[] {
    const { types } = this.metadata;
    const { PDF } = BreGuidesRate;
    const lines: TenantJobLine[] = [];

    if (types.length > 0) {
      lines.push({
        id: 'pdf_guides',
        description: `PDF Guides (x${types.length})`,
        amount: new Big(PDF).times(types.length),
      });
    }

    return lines;
  }

  expenseLines(): TenantJobLine[] {
    const { types } = this.metadata;
    const { PDF } = BreGuidesRate;
    const lines: TenantJobLine[] = [];

    if (types.length > 0) {
      lines.push({
        id: 'pdf_guides',
        description: `PDF Guides (x${types.length})`,
        amount: new Big(PDF).times(types.length).times('0.85'),
      });
    }

    return lines;
  }
}
