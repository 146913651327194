import { useEffect } from 'react';
import InputRadioCards, { InputRadioCardsProps } from '~/components/input/InputRadioCards';
import { useZodFormFieldSingle } from '~/components/zod/ZodForm';

interface ZodFieldRadioCardsProps extends Omit<InputRadioCardsProps, 'onChange' | 'value'> {
  name: string;
  default?: string | null;
}

export default function ZodFieldRadioCards({ name, ...props }: ZodFieldRadioCardsProps) {
  const [prefixed, value, setValue] = useZodFormFieldSingle(name);

  useEffect(() => {
    if (props.default && !value) {
      setValue(props.default);
    }
  }, []);

  return <InputRadioCards {...props} name={prefixed} value={value} onChange={setValue} />;
}
