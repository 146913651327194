import { BreOrderType } from '~/tenants/bre/model/BreOrderContext';

const BaseRates = {
  COMBO: '525',
  FIVE: '160',
  POI: '125',
  POI_EACH: '20',
  RUSH: '150',
  TEN: '315',
  TWILIGHT: '315',
  VIDEO: '315',
};

export const BreAerialRate: Record<BreOrderType, typeof BaseRates> = {
  [BreOrderType.COMMERCIAL]: {
    ...BaseRates,
    COMBO: '840',
    FIVE: '210',
    POI: '160',
    TEN: '420',
    VIDEO: '420',
  },
  [BreOrderType.RESIDENTIAL]: BaseRates,
  [BreOrderType.RENTAL]: BaseRates,
  [BreOrderType.PROPERTY_MKTG]: BaseRates,
  [BreOrderType.AGENT_MKTG]: BaseRates,
};

const BasePays = {
  COMBO: '500',
  FIVE: '150',
  TEN: '299',
  TWILIGHT: '195',
  VIDEO: '299',
};

export const BreAerialPay: Record<BreOrderType, typeof BasePays> = {
  [BreOrderType.COMMERCIAL]: BasePays,
  [BreOrderType.RESIDENTIAL]: BasePays,
  [BreOrderType.RENTAL]: BasePays,
  [BreOrderType.PROPERTY_MKTG]: BasePays,
  [BreOrderType.AGENT_MKTG]: BasePays,
};
