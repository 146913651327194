import FormGroup from '~/components/form/FormGroup';
import { FormHorizontal } from '~/components/form/layout';
import { useTz } from '~/components/hooks/useTz';
import { InputCardOption } from '~/components/input/InputCards';
import ZodFieldDate from '~/components/zod/ZodFieldDate';
import ZodFieldCards from '~/components/zod/ZodFieldCards';
import { isBusinessDay, plusBusinessDays } from '~/lib/datettime';
import { capitalize } from '~/lib/format';
import { BreOrderContext } from '~/tenants/bre/model/BreOrderContext';
import { layoutSchema } from '~/tenants/bre/performable/print/design/BreDesignConfig';
import { BreDesignRate } from '~/tenants/bre/performable/print/design/BreDesignData';
import { countSchema } from '~/tenants/bre/performable/print/postcards/printonly/BrePostcardsPrintOnlyConfig';
import { BrePostcardsPrintOnlyRate } from '~/tenants/bre/performable/print/postcards/printonly/BrePostcardsPrintOnlyData';
import { TenantPerformableFormProps } from '~/tenants/common/form';
import { TenantPackageAddOnRadioCards } from '~/tenants/common/package';
import { TenantPerformableAddOns } from '~/tenants/common/performable';

const COUNT_OPTS = countSchema.options.map<InputCardOption>((value) => ({
  title: `${value} Pieces`,
  cost: BrePostcardsPrintOnlyRate[value],
  value,
}));

export default function BrePostcardsPrintOnlyForm({ persona }: TenantPerformableFormProps<BreOrderContext>) {
  const tz = useTz();

  const designOptions = layoutSchema.options.map<InputCardOption>((value) => {
    const rate = BreDesignRate[value].postcard_half;

    return {
      title: `${capitalize(value)} Layout`,
      cost: rate,
      disabled: !rate,
      value,
    };
  });

  return (
    <FormGroup>
      <FormHorizontal name="count" label="Count" required>
        <ZodFieldCards name="count" options={COUNT_OPTS} required />
      </FormHorizontal>
      <FormHorizontal name="need_by" label="Need by" required>
        <ZodFieldDate
          name="need_by"
          filter={(date) => isBusinessDay(date) && date >= plusBusinessDays(tz().startOf('day'), 1)}
        />
      </FormHorizontal>

      <TenantPerformableAddOns persona={persona}>
        <TenantPackageAddOnRadioCards
          name="design"
          title="Design"
          description="Include layout design to your flyers"
          image="" // ? what image should we use here
          persona={persona}
          options={designOptions}
          cols={2}
          required
        />
      </TenantPerformableAddOns>
    </FormGroup>
  );
}
