import { TenantPerformableFormProps } from '~/tenants/common/form';
import { FormHorizontal } from '~/components/form/layout';
import ZodFieldSelect from '~/components/zod/ZodFieldSelect';
import { MpiVideoType } from './MpiVideoConfig';
import { MpiOrderContext } from '../../model/MpiOrder';
import { useZodFormFieldSingleValue } from '~/components/zod/ZodForm';
import { TenantPerformableAddOns } from '~/tenants/common/performable';
import MpiVideoAddOns from './MpiVideoAddOns';

export default function MpiVideoForm({ persona }: TenantPerformableFormProps<MpiOrderContext>) {
  const type = useZodFormFieldSingleValue<MpiVideoType>('type');

  const description = (() => {
    switch (type) {
      case MpiVideoType.WALK_THROUGH_AERIAL:
        return 'Up to 2 Minutes Final Video';
      case MpiVideoType.WALK_THROUGH:
        return 'Up to 1.5 Minutes Final Video';
      case MpiVideoType.AERIAL:
        return 'Up to 30 Seconds Final Video';
    }
  })();

  return (
    <>
      <FormHorizontal name="type" label="Type" description={description}>
        <ZodFieldSelect
          options={[
            { label: 'Home Video Tour with Aerial Drone Video', value: MpiVideoType.WALK_THROUGH_AERIAL },
            { label: 'Home Video Tour', value: MpiVideoType.WALK_THROUGH },
            { label: 'Stand-Alone Drone Video', value: MpiVideoType.AERIAL },
          ]}
          name="type"
        />
      </FormHorizontal>
      <TenantPerformableAddOns persona={persona}>
        <MpiVideoAddOns />
      </TenantPerformableAddOns>
    </>
  );
}
