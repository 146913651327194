import { registerTenant, TenantId } from '~/tenants/common/registry';
import { TimeZone } from '~/lib/enum';
import { TwtOrder, TwtOrderSchema } from '~/tenants/twt/model/TwtOrder';
import TwtOrderForm from '~/tenants/twt/forms/TwtOrderForm';
import TwtOrderWizardType from '~/tenants/twt/forms/TwtOrderWizardType';
import TwtPhotoConfig from '~/tenants/twt/performable/photo/TwtPhotoConfig';
import TwtCustomer, { TwtCustomerSchema } from '~/tenants/twt/model/TwtCustomer';
import TwtProviderForm from '~/tenants/twt/forms/TwtProviderForm';
import TwtCustomerForm from '~/tenants/twt/forms/TwtCustomerForm';
import { TWT_PROVIDER_DATA } from '~/tenants/twt/data';
import TwtProvider from '~/tenants/twt/model/TwtProvider';
import { Holiday } from '~common/holidays/holidays';
import TwtEditingConfig from '~/tenants/twt/performable/editing/TwtEditingConfig';
import TwtOrderTimes from '~/tenants/twt/forms/TwtOrderTimes';
import TwtOnboardingForm from '~/tenants/twt/forms/TwtOnboardingForm';
import TwtPhotoReshootForm from './performable/photo/TwtPhotoReshootForm';
import TwtOrderPayment from '~/tenants/twt/forms/TwtOrderPayment';
import { DeliverableType } from '~common/model/Deliverable';
import TwtEditingAction from '~/tenants/twt/performable/editing/TwtEditingAction';
import TWT_PERFORMABLES from '~/tenants/twt/register/configs';
import TWT_PACKAGES from '~/tenants/twt/register/packages';

export default function registerTwt() {
  registerTenant(TenantId.TWIST_TOURS, TwtOrderSchema, {
    name: 'Twist Tours',
    domain: 'order.twisttours.com',
    background: '#f9590f',
    phone: '+18666480022',
    tax: '0.0825',
    smsPrefix: 'Twist Tours, ',
    rainforestEnable: true,
    rainforestMerchantId: 'mid_2lOERJFs1f03OkQLaVT7cn6kj8q',
    rainforestMerchantApplicationId: 'app_2lOERHnj4mSI9srhmchUCyAOFM5',
    logoRaster: 'https://media.photology.co/tenant/twt/logo.png',
    logoVector: 'https://media.photology.co/tenant/twt/logo.svg',
    legal: 'TBD',
    email: 'office@twisttours.com',
    emailEnable: true,
    emailOrderCompleteDisabled: true,
    inboxEnabled: true,
    nylasProviderInvite: true,
    providers: TWT_PROVIDER_DATA,
    providerClass: TwtProvider,
    wizardDay: true,
    wizardProvider: true,
    wizardServicesTitle: 'Photos Only & A La Carte Services',
    holidays: [Holiday.CHRISTMAS, Holiday.THANKSGIVING, Holiday.NEW_YEARS, Holiday.DAY_AFTER_THANKSGIVING],
    scheduleStartTime: '07:00',
    scheduleEndTime: '20:00',
    scheduleJobColor: true,
    scheduleHideCustomer: true,
    autorespondStart: '17:00',
    autorespondEnd: '08:30',
    autorespondWeekend: true,
    autorespondKeywords: {
      prepare:
        'To see a guide of how to prepare for your shoot, please visit: https://www.twisttours.com/how-to-prep-for-a-photo-shoot/',
    },
    autorespondHolidays: {
      [Holiday.NEW_YEARS_EVE]:
        'Thank you for reaching out! We are currently closed for New Year’s Eve, but we will get back to you as soon as we can!',
      [Holiday.NEW_YEARS]:
        'Thank you for reaching out! We are currently closed for New Year’s Day, but we will get back to you as soon as we can!',
      [Holiday.THANKSGIVING]:
        'Thank you for reaching out! We are currently closed for Thanksgiving, but we will get back to you as soon as we can!',
      [Holiday.JULY_4TH]:
        'Thank you for reaching out! We are currently closed for July 4th, but we will get back to you as soon as we can!',
      [Holiday.MEMORIAL_DAY]:
        'Thank you for reaching out! We are currently closed for Memorial Day, but we will get back to you as soon as we can!',
      [Holiday.CHRISTMAS_EVE]:
        'Thank you for reaching out and Happy Holidays! We are currently closed in observance of the holiday, but we will get back to you as soon as we can!',
      [Holiday.CHRISTMAS]:
        'Thank you for reaching out and Happy Holidays! We are currently closed in observance of the holiday, but we will get back to you as soon as we can!',
    },
    autorespondMessage:
      'Hello! Twist Tours is closed - please note we will have a delayed response outside of business hours. We are open M-F, 8:30am - 5pm.',
    locations: [
      {
        slug: 'atx',
        name: 'Austin',
        timezone: TimeZone.US_CENTRAL,
        address: {
          line1: '5618 Steven Creek Way',
          city: 'Austin',
          state: 'TX',
          zip: '78721',
          long: -97.6867845,
          lat: 30.2591101,
        },
      },
      {
        slug: 'san',
        name: 'San Antonio',
        timezone: TimeZone.US_CENTRAL,
        address: {
          line1: 'Downtown',
          city: 'San Antonio',
          state: 'TX',
          zip: '78205',
          long: -98.4891503389352,
          lat: 29.428041061444006,
        },
      },
    ],
    // TODO: make typescript happy
    orderClass: TwtOrder,
    customerClass: TwtCustomer,
    nylasGrantId: '9b49b779-6c2d-402a-883a-5dce1c0c25dd',
    //nylasProviderInvite: true,
    resend: 're_5aDzRJPq_X2LfmZKyb6nMEG989ourG2vE',
    userback: true,
    customerSchema: TwtCustomerSchema,
    forms: {
      orderConfigure: TwtOrderForm,
      wizardType: TwtOrderWizardType,
      provider: TwtProviderForm,
      customer: TwtCustomerForm,
      payment: TwtOrderPayment,
      times: TwtOrderTimes,
      onboarding: TwtOnboardingForm,
    },
    performables: TWT_PERFORMABLES,
    packages: TWT_PACKAGES,
    actions: [
      {
        id: 'edit',
        name: 'Request Edits',
        deliverable: DeliverableType.PHOTO,
        job: TwtEditingConfig,
        form: TwtEditingAction,
      },
      {
        id: 'reshoot',
        name: 'Request Reshoot',
        deliverable: DeliverableType.PHOTO,
        job: TwtPhotoConfig,
        form: TwtPhotoReshootForm,
      },
    ],
  });
}
