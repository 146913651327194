import FormGroup from '~/components/form/FormGroup';
import { FormHorizontal } from '~/components/form/layout';
import { InputCardOption } from '~/components/input/InputCards';
import { InputSelectOption } from '~/components/input/InputSelect';
import ZodFieldCards from '~/components/zod/ZodFieldCards';
import ZodFieldSelect from '~/components/zod/ZodFieldSelect';
import { useZodFormFieldSingleValue } from '~/components/zod/ZodForm';
import { capitalize } from '~/lib/format';
import {
  DesignLayout,
  DesignType,
  layoutSchema,
  typeSchema,
} from '~/tenants/bre/performable/print/design/BreDesignConfig';
import { BreDesignRate } from '~/tenants/bre/performable/print/design/BreDesignData';
import BreDesignJob from '~/tenants/bre/performable/print/design/BreDesignJob';

export default function BreDesignForm() {
  const layout = useZodFormFieldSingleValue<DesignLayout>('layout') ?? 'new';
  const type = useZodFormFieldSingleValue<DesignType>('type') ?? 'postcard_half';

  const layoutOptions = layoutSchema.options.map<InputCardOption>((value) => ({
    title: `${capitalize(value)} Layout`,
    description: 'design',
    disabled: !BreDesignRate[value][type],
    value,
  }));

  const typeOptions = typeSchema.options
    .filter((value) => BreDesignRate[layout][value])
    .map<InputSelectOption>((value) => ({ label: BreDesignJob.typeName(value), value }));

  return (
    <FormGroup>
      <FormHorizontal name="layout" label="Layout" required>
        <ZodFieldCards name="layout" options={layoutOptions} cols={2} required />
      </FormHorizontal>
      <FormHorizontal name="type" label="Type" required>
        <ZodFieldSelect name="type" options={typeOptions} />
      </FormHorizontal>
    </FormGroup>
  );
}
