import Big from 'big.js';
import { match } from 'ts-pattern';
import { TenantJobLine } from '~/lib/model';
import { FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata } from '~/lib/zod';
import BreJob from '~/tenants/bre/model/BreJob';
import { BreOrderType } from '~/tenants/bre/model/BreOrderContext';
import BreTwilightConfig from '~/tenants/bre/performable/twilight/BreTwilightConfig';
import { BreTwilightRate } from '~/tenants/bre/performable/twilight/BreTwilightData';
import { PersonaType } from '~/tenants/common/TenantPersona';
import { DeliverableType } from '~common/model/Deliverable';
import { MicrositeFileType } from '~microsite/lib/types';

export default class BreTwilightJob extends BreJob<typeof BreTwilightConfig> {
  get performable() {
    return BreTwilightConfig;
  }

  get configurable(): boolean {
    return true;
  }

  get deliverable() {
    return DeliverableType.PHOTO;
  }

  get media() {
    return {
      persona: PersonaType.PROVIDER,
      microsite: MicrositeFileType.GALLERY,
    };
  }

  get eligible(): boolean {
    return !this.isMarketing;
  }

  defaultValue(): ZodVersionedMetadata<(typeof BreTwilightConfig)['schema']> {
    return {
      version: FIRST_VERSION_TIMESTAMP,
      photos: this.order.metadata.type === BreOrderType.COMMERCIAL ? 15 : 8,
      shoot_date: '' as TDateISODate,
    };
  }

  onsite(): number {
    const { photos } = this.metadata;

    return match(photos)
      .when(
        (p) => p <= 8,
        () => 40,
      )
      .when(
        (p) => p <= 15,
        () => 50,
      )
      .otherwise(() => 60);
  }

  revenueLines(): TenantJobLine[] {
    const { photos, copyright } = this.metadata;
    const { COPYRIGHT, PHOTOS_8, PHOTOS_15, PHOTOS_25 } = BreTwilightRate[this.order.metadata.type];
    const lines: TenantJobLine[] = [];

    if (photos <= 8) {
      lines.push({
        id: 'twilight',
        description: '8 Twilight Photos',
        amount: new Big(PHOTOS_8),
      });
    } else if (photos <= 15) {
      lines.push({
        id: 'twilight',
        description: '15 Twilight Photos',
        amount: new Big(PHOTOS_15),
      });
    } else if (photos <= 25) {
      lines.push({
        id: 'twilight',
        description: '25 Twilight Photos',
        amount: new Big(PHOTOS_25),
      });
    }

    if (copyright) {
      lines.push({
        id: 'copyright',
        description: 'Release w/ copyright',
        amount: new Big(COPYRIGHT).times(photos),
      });
    }

    return lines;
  }

  expenseLines(): TenantJobLine[] {
    const { photos } = this.metadata;
    const lines: TenantJobLine[] = [];
    const split = '0.65';

    if (photos <= 8) {
      lines.push({
        id: 'twilight',
        description: '8 Twilight Photos',
        amount: new Big('395').times(split),
      });
    } else if (photos <= 15) {
      lines.push({
        id: 'twilight',
        description: '15 Twilight Photos',
        amount: new Big('475').times(split),
      });
    } else if (photos <= 25) {
      lines.push({
        id: 'twilight',
        description: '25 Twilight Photos',
        amount: new Big('575').times(split),
      });
    }

    return lines;
  }
}
