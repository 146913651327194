import z from 'zod';
import { GeocodedAddressSchema } from '~/lib/model';
import { createPerformableConfig, TenantId } from '~/tenants/common/registry';
import { coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP } from '~/lib/zod';

export enum MpiBrochuresQuantity {
  QTY_25 = '25',
  QTY_50 = '50',
  QTY_100 = '100',
  QTY_250 = '250',
}

export enum MpiBrochureShipping {
  STANDARD = 'standard',
  RUSH = 'rush',
}

export enum MpiBrochuresOption {
  TWO = '2',
  FOUR = '4',
  EIGHT = '8',
  TWELVE = '12',
  SIXTEEN = '16',
}

export default createPerformableConfig(
  'brochures',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
      option: z.nativeEnum(MpiBrochuresOption),
      quantity: z.nativeEnum(MpiBrochuresQuantity),
      shipping: z.nativeEnum(MpiBrochureShipping).optional(),
      delivery: GeocodedAddressSchema.optional(),
    }),
  },
  {
    name: 'Brochures',
    // TODO: Joel: Need to revisit this
    thumbnail: 'tenant/nep/public/brochures.jpg',
    short:
      'High quality pront material, professionally designed and printed, poised to impress clients and potential buysers alike. Turn around time varies based off the size of your job. Please contact MPI if you have any questions before ordering.',
    tenant: TenantId.MPI,
    images: [],
    group: 'Print',
  },
);
