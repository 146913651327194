import ZodFieldSelect from '~/components/zod/ZodFieldSelect';
import { TenantPerformableFormProps } from '~/tenants/common/form';
import { TenantPackageAddOnGalleryExamplesCheckbox } from '~/tenants/common/package';
import { MpiOrderContext } from '../../model/MpiOrder';
import { format_money } from '~/components/Money';
import { MPI_PHOTO_COMMUNITY } from './MpiPhotosJob';
import Big from 'big.js';
import IconSolidNew from '~/components/icons/streamline/solid/IconSolidNew';
import IconText from '~/components/layout/IconText';
import { useZodFormFieldArray, useZodFormFieldObject } from '~/components/zod/ZodForm';
import { DistanceAddress } from '~/lib/model';
import ZodFieldAddress from '~/components/zod/ZodFieldAddress';
import classNames from 'classnames';
import Button from '~/components/interactive/Button';

function communityExtraPhotos(count: number) {
  return {
    label: `15 Minute Shoot, ${count} Community Photos: ${format_money(new Big(MPI_PHOTO_COMMUNITY).toFixed(2))}`,
    value: count.toString(),
  };
}

function Address({ name, remove }: { name: string; remove: () => void }) {
  const [_name, address] = useZodFormFieldObject<DistanceAddress>(name);

  return (
    <div className="flex gap-4 align-baseline">
      <div
        className={classNames('', {
          'flex-1': !address?.city,
        })}
      >
        <ZodFieldAddress name={name} key={name} />
      </div>
      {address?.city && (
        <Button type="button" variant="outline" size="short" onClick={remove}>
          remove
        </Button>
      )}
    </div>
  );
}

export default function MpiPhotosCommunity({ persona }: TenantPerformableFormProps<MpiOrderContext>) {
  const [communityAdress, addAddress] = useZodFormFieldArray('community_adress');

  return (
    <TenantPackageAddOnGalleryExamplesCheckbox
      title="Community Photos"
      // TODO: Joel: Add proper images
      images={['tenant/twt/residential/1.jpg']}
      name="community"
      persona={persona}
      description="Need some community photos?"
    >
      <div className="flex flex-col gap-4">
        <ZodFieldSelect
          options={[
            communityExtraPhotos(5),
            communityExtraPhotos(10),
          ]}
          name="community_photos"
        />
        {...communityAdress.map((a) => <Address {...a} />)}
        <IconText size="small" icon={<IconSolidNew />} text="Add Point of Interest" onClick={addAddress} />
      </div>
    </TenantPackageAddOnGalleryExamplesCheckbox>
  );
}
