import dedent from 'dedent';
import FormGroup from '~/components/form/FormGroup';
import { FormHorizontal } from '~/components/form/layout';
import { InputSelectOption } from '~/components/input/InputSelect';
import ZodFieldTextarea from '~/components/zod/ZodFieldTextarea';
import { BreOrderContext, BreOrderType } from '~/tenants/bre/model/BreOrderContext';
import BrePhotosFields from '~/tenants/bre/performable/photos/BrePhotosFields';
import { BrePhotosRate } from '~/tenants/bre/performable/photos/BrePhotosData';
import BrePhotosLocalAreaAddOn from '~/tenants/bre/performable/photos/BrePhotosLocalAreaAddOn';
import { TenantPerformableFormProps } from '~/tenants/common/form';
import { TenantPackageAddOnCheckbox } from '~/tenants/common/package';
import { TenantPerformableAddOns } from '~/tenants/common/performable';
import BreListingDisclosure from '~/tenants/bre/performable/BreListingDisclosure';

export const PHOTOS_OPTS = Array.from({ length: 11 })
  .map((_, i) => i * 10 + 15)
  .map<InputSelectOption>((value) => ({
    label: `${value} Photos`,
    value: `${value}`,
  }));

export default function BrePhotosForm({ context, persona }: TenantPerformableFormProps<BreOrderContext>) {
  const { COPYRIGHT, RUSH, TWILIGHT } = BrePhotosRate[context.metadata.type];

  return (
    <FormGroup>
      <BrePhotosFields metadata={context.metadata} />
      <FormHorizontal name="notes" label="Special Instructions">
        <ZodFieldTextarea name="notes" />
      </FormHorizontal>

      <TenantPerformableAddOns persona={persona}>
        {context.metadata.type === BreOrderType.COMMERCIAL && (
          <TenantPackageAddOnCheckbox
            name="copyright"
            title="Copyright"
            description="Release photos with copyrights"
            image="" // ? what image should we use here
            persona={persona}
            cost={COPYRIGHT}
            costEach
          />
        )}
        <TenantPackageAddOnCheckbox
          name="rush"
          title="Rush"
          description="Need your photos before noon on the next business day?"
          image="/tenant/bre/rush.jpg"
          persona={persona}
          cost={RUSH}
        />
        <TenantPackageAddOnCheckbox
          name="twilight"
          title="Same-day Twilight"
          description={dedent`
            Photos include exterior, interior, and twilight shots, all taken in \
            one session. For twilight-only photos, select the Twilight – Separate \
            Trip option, allowing a different shoot date if needed.
          `}
          image="/tenant/bre/photos/twilight.jpg"
          persona={persona}
          cost={TWILIGHT}
        />
        <BrePhotosLocalAreaAddOn type={context.metadata.type} persona={persona} />
      </TenantPerformableAddOns>

      <BreListingDisclosure />
    </FormGroup>
  );
}
