import React from 'react';
import { TenantPerformableAddOns } from '~/tenants/common/performable';
import { PersonaType } from '~/tenants/common/TenantPersona';
import { PackageFormNested } from '~/components/performable/PackageForm';
import ZodFieldHidden from '~/components/zod/ZodFieldHidden';
import { TenantPackageInclude, TenantPackageIncludes } from '~/tenants/common/package';
import TwtPhotosNeighborhood from '~/tenants/twt/performable/photo/TwtPhotosNeighborhood';
import TwtPhotoConfig, { TwtPhotoType } from '~/tenants/twt/performable/photo/TwtPhotoConfig';
import TwtReelConfig from '~/tenants/twt/performable/reel/TwtReelConfig';

export default function TwtJustinForm() {
  return (
    <>
      <TenantPackageIncludes>
        <TenantPackageInclude name="35 - 40 Photo Tour" image="tenant/twt/residential/1.jpg" />
        <TenantPackageInclude name="Custom Vertical Video" image="tenant/twt/reel/thumbnail.png" />
      </TenantPackageIncludes>
      <TenantPerformableAddOns persona={PersonaType.CUSTOMER}>
        <PackageFormNested performable={TwtPhotoConfig}>
          <ZodFieldHidden name="type" value={TwtPhotoType.INTERIOR_EXTERIOR} />
          <TwtPhotosNeighborhood persona={PersonaType.CUSTOMER} />
        </PackageFormNested>
        <PackageFormNested performable={TwtReelConfig} />
      </TenantPerformableAddOns>
    </>
  );
}
