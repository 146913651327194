import { createPerformableConfig, TenantId } from '~/tenants/common/registry';
import z from 'zod';
import { coerceBoolean, coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP } from '~/lib/zod';

export enum MpiVideoType {
  WALK_THROUGH_AERIAL = 'walk_through_aerial',
  WALK_THROUGH = 'walk_through',
  AERIAL = 'aerial',
}

export default createPerformableConfig(
  'video',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
      type: z.nativeEnum(MpiVideoType),
      agent_on_camera: coerceBoolean().optional(),
    }),
  },
  {
    name: 'Listing videography',
    images: [],
    thumbnail: 'tenant/twt/video/thumbnail.png',
    short:
      'Our listing videography provides a full interior and exterior walkthrough of the home, with add-on options that include aerial video and an agent-guided walkthrough.',
    tenant: TenantId.MPI,
  },
);
