import { TenantPerformable } from '~/tenants/common/registry';
import TwtPhotoConfig from '~/tenants/twt/performable/photo/TwtPhotoConfig';
import TwtPhotoForm from '~/tenants/twt/performable/photo/TwtPhotoForm';
import TwtPhotoJob from '~/tenants/twt/performable/photo/TwtPhotoJob';
import TwtTwilightConfig from '~/tenants/twt/performable/twilight/TwtTwilightConfig';
import { TwtTwilightForm } from '~/tenants/twt/performable/twilight/TwtTwilightForm';
import { TwtTwilightJob } from '~/tenants/twt/performable/twilight/TwtTwilightJob';
import TwtMatterportConfig from '~/tenants/twt/performable/matterport/TwtMatterportConfig';
import TwtMatterportForm from '~/tenants/twt/performable/matterport/TwtMatterportForm';
import TwtMatterportJob from '~/tenants/twt/performable/matterport/TwtMatterportJob';
import TwtVideoConfig from '~/tenants/twt/performable/video/TwtVideoConfig';
import TwtVideoForm from '~/tenants/twt/performable/video/TwtVideoForm';
import { TwtVideoJob } from '~/tenants/twt/performable/video/TwtVideoJob';
import TwtFloorplanConfig from '~/tenants/twt/performable/floorplan/TwtFloorplanConfig';
import TwtFloorplanForm from '~/tenants/twt/performable/floorplan/TwtFloorplanForm';
import TwtFloorplanJob from '~/tenants/twt/performable/floorplan/TwtFloorplanJob';
import TwtAerialConfig from '~/tenants/twt/performable/aerial/TwtAerialConfig';
import TwtAerialForm from '~/tenants/twt/performable/aerial/TwtAerialForm';
import { TwtAerialJob } from '~/tenants/twt/performable/aerial/TwtAerialJob';
import TwtReelConfig from '~/tenants/twt/performable/reel/TwtReelConfig';
import TwtReelForm from '~/tenants/twt/performable/reel/TwtReelForm';
import { TwtReelJob } from '~/tenants/twt/performable/reel/TwtReelJob';
import TwtSlideshowConfig from '~/tenants/twt/performable/slideshow/TwtSlideshowConfig';
import EmptyForm from '~/tenants/common/forms/EmptyForm';
import { TwtSlideshowJob } from '~/tenants/twt/performable/slideshow/TwtSlideshowJob';
import TwtEditingConfig from '~/tenants/twt/performable/editing/TwtEditingConfig';
import TwtEditingForm from '~/tenants/twt/performable/editing/TwtEditingForm';
import { TwtEditingJob } from '~/tenants/twt/performable/editing/TwtEditingJob';
import TwtVirtualConfig from '~/tenants/twt/performable/virtual/TwtVirtualConfig';
import TwtVirtualForm from '~/tenants/twt/performable/virtual/TwtVirtualForm';
import { TwtVirtualJob } from '~/tenants/twt/performable/virtual/TwtVirtualJob';
import TwtStagingConfig from '~/tenants/twt/performable/staging/TwtStagingConfig';
import TwtStagingForm from '~/tenants/twt/performable/staging/TwtStagingForm';
import { TwtStagingJob } from '~/tenants/twt/performable/staging/TwtStagingJob';
import TwtZillowConfig from '~/tenants/twt/performable/zillow/TwtZillowConfig';
import TwtZillowJob from '~/tenants/twt/performable/zillow/TwtZillowJob';
import TwtMatterportVideoConfig from '~/tenants/twt/performable/matterport-video/TwtMatterportVideoConfig';
import { TwtMatterportVideoJob } from '~/tenants/twt/performable/matterport-video/TwtMatterportVideoJob';

const TWT_PERFORMABLES: Record<string, TenantPerformable<any, any, any>> = {
  [TwtPhotoConfig.id]: {
    config: TwtPhotoConfig,
    form: TwtPhotoForm,
    job: TwtPhotoJob,
    addons: [TwtStagingConfig.id, TwtVirtualConfig.id, TwtEditingConfig.id],
  },
  [TwtTwilightConfig.id]: {
    config: TwtTwilightConfig,
    form: TwtTwilightForm,
    job: TwtTwilightJob,
  },
  [TwtMatterportConfig.id]: {
    config: TwtMatterportConfig,
    form: TwtMatterportForm,
    job: TwtMatterportJob,
  },
  [TwtVideoConfig.id]: {
    config: TwtVideoConfig,
    form: TwtVideoForm,
    job: TwtVideoJob,
  },
  [TwtFloorplanConfig.id]: {
    config: TwtFloorplanConfig,
    form: TwtFloorplanForm,
    job: TwtFloorplanJob,
  },
  [TwtAerialConfig.id]: {
    config: TwtAerialConfig,
    form: TwtAerialForm,
    job: TwtAerialJob,
  },
  [TwtReelConfig.id]: {
    config: TwtReelConfig,
    form: TwtReelForm,
    job: TwtReelJob,
  },
  [TwtSlideshowConfig.id]: {
    config: TwtSlideshowConfig,
    form: EmptyForm,
    job: TwtSlideshowJob,
  },
  [TwtEditingConfig.id]: {
    config: TwtEditingConfig,
    form: TwtEditingForm,
    job: TwtEditingJob,
  },
  [TwtVirtualConfig.id]: {
    config: TwtVirtualConfig,
    form: TwtVirtualForm,
    job: TwtVirtualJob,
  },
  [TwtStagingConfig.id]: {
    config: TwtStagingConfig,
    form: TwtStagingForm,
    job: TwtStagingJob,
  },
  [TwtZillowConfig.id]: {
    config: TwtZillowConfig,
    form: EmptyForm,
    job: TwtZillowJob,
  },
  [TwtMatterportVideoConfig.id]: {
    config: TwtMatterportVideoConfig,
    form: EmptyForm,
    job: TwtMatterportVideoJob,
  },
};

export default TWT_PERFORMABLES;
