import { ReactNode } from 'react';
import Heading from '~/components/layout/Heading';
import PerformableGroupForm from '~/components/performable/PerformableGroupForm';
import { TenantPerformableConfig } from '~/tenants/common/TenantJob';

interface BrePackageGroupProps {
  subtitle: string;
  performable: TenantPerformableConfig;
  children: ReactNode;
}

export default function BrePackageGroup({ children, performable, subtitle }: BrePackageGroupProps) {
  return (
    <>
      <Heading title={performable.name} subtitle={subtitle} />

      <PerformableGroupForm performable={performable}>{children}</PerformableGroupForm>
    </>
  );
}
