import { BreOrderContext, BreOrderType } from '~/tenants/bre/model/BreOrderContext';
import { BreMatterportRate } from '~/tenants/bre/performable/matterport/BreMatterportData';
import { PersonaType } from '~/tenants/common/TenantPersona';
import { TenantPackageAddOnCheckbox } from '~/tenants/common/package';

interface BreMatterportAddOnsProps {
  context: BreOrderContext;
  persona: PersonaType;
}

export default function BreMatterportAddOns({ context, persona }: BreMatterportAddOnsProps) {
  const { type } = context.metadata;
  const { OUTDOOR, OUTDOOR_XL } = BreMatterportRate[type];
  const isCommercial = type === BreOrderType.COMMERCIAL;

  if (type === BreOrderType.AGENT_MKTG || type === BreOrderType.PROPERTY_MKTG) {
    return null;
  }

  return (
    <>
      {isCommercial && (
        <TenantPackageAddOnCheckbox
          name="outdoor_commercial"
          title="Outdoors"
          description="Add outdoors scan"
          image="" // ? what should we use here
          persona={persona}
          cost={context.metadata.sqft < 8000 ? OUTDOOR : OUTDOOR_XL}
        />
      )}
      {!isCommercial && (
        <>
          <TenantPackageAddOnCheckbox
            name="outdoor_front"
            title="Front Yard"
            description="Add a front yard scan"
            image="/tenant/bre/matterport/frontyard.png"
            persona={persona}
            cost={context.metadata.sqft < 8000 ? OUTDOOR : OUTDOOR_XL}
          />
          <TenantPackageAddOnCheckbox
            name="outdoor_back"
            title="Back Yard"
            description="Add a back yard scan"
            image="/tenant/bre/matterport/backyard.png"
            persona={persona}
            cost={context.metadata.sqft < 8000 ? OUTDOOR : OUTDOOR_XL}
          />
        </>
      )}
    </>
  );
}
