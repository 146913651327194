import z from 'zod';
import { createPerformableConfig, TenantId } from '~/tenants/common/registry';
import { coerceBoolean, coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP } from '~/lib/zod';
import dedent from 'dedent';

export default createPerformableConfig(
  'floorplan',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
      rush: coerceBoolean().optional(),
    }),
  },
  {
    name: '2D Color Floorplan',
    group: 'Listing',
    tenant: TenantId.BEYOND_RE_MARKETING,
    thumbnail: '/tenant/bre/floorplan/thumbnail.jpg',
    images: [],
    short: dedent`
      We provide Earth-Tone Color 2D Floorplans to complement your 3D scans or \
      photography packages. Available as a standalone service, our floorplans \
      add clarity and style to your property visuals. Pricing varies by square \
      footage.
    `,
  },
);
