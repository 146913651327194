import FormGroup from '~/components/form/FormGroup';
import { BreOrderContext } from '~/tenants/bre/model/BreOrderContext';
import { BreBoostAddOn } from '~/tenants/bre/performable/BreBoostAddOn';
import BreListingDisclosure from '~/tenants/bre/performable/BreListingDisclosure';
import BreSocialAddOns from '~/tenants/bre/performable/social/BreSocialAddOns';
import { BreSocialRate } from '~/tenants/bre/performable/social/BreSocialData';
import { TenantPerformableFormProps } from '~/tenants/common/form';
import { TenantPackageAddOnCheckbox } from '~/tenants/common/package';
import { TenantPerformableAddOns } from '~/tenants/common/performable';

export default function BreSocialForm({ context, persona }: TenantPerformableFormProps<BreOrderContext>) {
  const { RUSH } = BreSocialRate[context.metadata.type];

  return (
    <FormGroup>
      <TenantPerformableAddOns persona={persona}>
        <TenantPackageAddOnCheckbox
          name="rush"
          title="Rush"
          description="Need your video by the next business day instead of the second business day?"
          image="/tenant/bre/rush.jpg"
          persona={persona}
          cost={RUSH}
        />
        <BreSocialAddOns type={context.metadata.type} persona={persona} />
        <BreBoostAddOn name="boost" persona={persona} />
      </TenantPerformableAddOns>

      <BreListingDisclosure />
    </FormGroup>
  );
}
