import { useState } from 'react';
import Message from '~/components/Message';
import { format_money } from '~/components/Money';
import useSubmitSchema from '~/components/hooks/useSubmitSchema';
import IconDelete from '~/components/icons/streamline/line/IconDelete';
import InputCheckbox from '~/components/input/InputCheckbox';
import Button from '~/components/interactive/Button';
import Modal, { ModalHeader, ModalWrapper } from '~/components/interactive/Modal';
import ValibotButton from '~/components/valibot/ValibotButton';
import ValibotFieldInput from '~/components/valibot/ValibotFieldInput';
import ValibotForm from '~/components/valibot/ValibotForm';
import ZodFieldHidden from '~/components/zod/ZodFieldHidden';
import { useZodFormFieldSingle } from '~/components/zod/ZodForm';
import { ApiDomainAvailableResponse } from '~/routes/api+/domain.available';
import { SCHEMA_API_DOMAIN_AVAILABLE } from '~/routes/api+/schema';
import { BreOrderType } from '~/tenants/bre/model/BreOrderContext';
import { BreWebsiteRate } from '~/tenants/bre/performable/website/BreWebsiteData';
import { PersonaType } from '~/tenants/common/TenantPersona';
import { TenantPackageAddOnTemplate } from '~/tenants/common/package';

interface BreWebsiteDomianAddOnProps {
  persona: PersonaType;
  type: BreOrderType;
}

export default function BreWebsiteDomianAddOn({ persona, type }: BreWebsiteDomianAddOnProps) {
  const { DOMAIN } = BreWebsiteRate[type];
  const [_domainName, domain, setDomain] = useZodFormFieldSingle('domain');
  const [response, onSubmit] = useSubmitSchema<ApiDomainAvailableResponse>(
    SCHEMA_API_DOMAIN_AVAILABLE,
    '/api/domain/available',
  );

  const [show, setShow] = useState(false);

  return (
    <TenantPackageAddOnTemplate
      title="Custom Domain"
      description="Choose a custom URL for your property website"
      image="/tenant/bre/website/domain.png"
      persona={persona}
      cost={format_money(DOMAIN)}
      enable={
        <InputCheckbox
          checked={!!domain}
          onChange={(checked) => {
            setShow(checked);
            setDomain(null);
          }}
        />
      }
    >
      {domain && (
        <div className="flex items-center space-x-2 pt-4">
          <Button
            type="button"
            variant="transparent"
            icon={<IconDelete />}
            onClick={(e) => {
              e.preventDefault();
              setDomain(null);
            }}
          />
          <p>{domain}</p>
          <ZodFieldHidden name="domain" value={domain} />
        </div>
      )}
      <Modal show={show} onClose={() => setShow(false)}>
        <ModalWrapper>
          <ModalHeader>Domain Search</ModalHeader>

          <ValibotForm schema={SCHEMA_API_DOMAIN_AVAILABLE} onSubmit={onSubmit}>
            <div className="p-4 w-[500px] space-y-4">
              {response && (
                <Message type={response.available ? 'success' : 'error'}>
                  {response.available ? 'That domain is available!' : 'That domain is not available.'}
                </Message>
              )}

              <div className="flex items-center space-x-2">
                <span className="text-sm">www.</span>
                <ValibotFieldInput name="domain" placeholder="123PeachtreeSt.com" />
                {response?.available && <ValibotButton>Search</ValibotButton>}
              </div>

              {!response && <ValibotButton showError>Search</ValibotButton>}

              {response?.available && (
                <div className="flex items-center border-t border-theme-separator pt-4">
                  <div className="w-28 shrink-0">
                    <Button
                      type="button"
                      variant="tenant"
                      onClick={() => {
                        setDomain(response.domain);
                        setShow(false);
                      }}
                    >
                      Register
                    </Button>
                  </div>
                  <p className="text-xs text-justify">
                    By continuing you acknowledge that when this order is submitted, you will be charged upfront for the
                    domain and that fee is non-refundable.
                  </p>
                </div>
              )}
            </div>
          </ValibotForm>
        </ModalWrapper>
      </Modal>
    </TenantPackageAddOnTemplate>
  );
}
