import z from 'zod';
import { FIRST_VERSION_TIMESTAMP, coerceLiteralNumberRequired, zodTDateISODate } from '~/lib/zod';
import { deliverySchema } from '~/tenants/bre/performable/common';
import { TenantId, createPerformableConfig } from '~/tenants/common/registry';

export type FolderCount = z.infer<typeof countSchema>;

export const countSchema = z.enum([
  '250',
  '500',
  '1000',
]);

export default createPerformableConfig(
  'folders',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
      count: countSchema,
      need_by: zodTDateISODate(),
      delivery: deliverySchema,
    }),
  },
  {
    name: 'Presentation Folders',
    group: 'Agent Marketing',
    images: [],
    tenant: TenantId.BEYOND_RE_MARKETING,
    short: 'Professional 16pt - 9" x 12" presentation folders with 2 pockets.',
  },
);
